import React from "react";
import { Box, CircularProgress } from "@mui/material";

const styles = {
    panel: {
        height: `100%`,
        width: `100%`,
        display: "flex",
        justifyContent: `center`,
        alignItems: `center`,
    },
};

export default (props) => {
    return (
        <Box sx={styles.panel} {...props}>
            <CircularProgress
                sx={{
                    color: "#4A73DD",
                }}
            />
        </Box>
    );
};
