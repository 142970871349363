import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";

export const apiTypes = {
    loadPublicData: createApiTypes("PUBLIC_DATA", "LOAD"),
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const publicReducer = createReducer({}, apiHandlers);

const getPublicData = (publicKey) => (dispatch) => {
    dispatch({
        types: apiTypes.loadPublicData,
        callAPI: () => proxy.publicDataGet(publicKey),
        payload: (state, { result }) => ({
            data: result,
        }),
    });
};

export const publicActions = {
    getPublicData,
};
