import _ from "lodash";
import { Dispatch } from "redux";
import {
    TournamentNodeApplyCommandsRequest,
    TournamentNodeApplyCommandsResponse,
    TournamentNodeGetRequest,
    TournamentNodeGetResponse,
} from "../../../Shared/helpers/api.tournamentNodes";
import { proxy } from "../../core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "../../core/utils";
import { tournamentNodeSelectors } from "../../helpers/selectors";
import { tournamentNodeMemberActions } from "./TournamentNodeMemberActions";

export const apiTypes = {
    loadTournamentNodeListType: createApiTypes("TOURNAMENT_NODE", "LOAD", false),
    applyCommandTournamentNodeType: createApiTypes("TOURNAMENT_NODE", "APPLY_COMMAND", false),
};

export const types = {
    RESET_TOURNAMENT_NODE_CACHE: "RESET_TOURNAMENT_NODE_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentNodeReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_NODE_CACHE]: (state: any) => ({ ...state, cache: [] }),
    },
);

const getTournamentNodeList = (request: TournamentNodeGetRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.loadTournamentNodeListType,
        callAPI: () => proxy.tournamentNodeGet(request),
        payload: (state: any, { result }: { result: TournamentNodeGetResponse }) => ({
            cache: [
                ...tournamentNodeSelectors
                    .getTournamentNodeCache(state)
                    .filter((item) => !_.isEqual(item.request, request)),
                {
                    request,
                    response: result,
                },
            ],
        }),
    });
};

const resetTournamentNodeCache = () => (dispatch: Dispatch<any>) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_NODE_CACHE,
    });
};

const applyTournamentBracketCommand =
    (
        request: TournamentNodeApplyCommandsRequest,
        onSuccess?: (response: TournamentNodeApplyCommandsResponse) => void,
    ) =>
    (dispatch: Dispatch<any>) => {
        return dispatch({
            types: apiTypes.applyCommandTournamentNodeType,
            callAPI: () => proxy.tournamentNodeApplyCommand(request),
            payload: (state: any, { result }: { result: TournamentNodeApplyCommandsResponse }) => {
                dispatch(resetTournamentNodeCache());
                dispatch(tournamentNodeMemberActions.resetTournamentNodeMemberCache());
                onSuccess?.(result);
            },
        });
    };

export const tournamentNodeActions = {
    getTournamentNodeList,
    resetTournamentNodeCache,
    applyTournamentBracketCommand,
};
