import _ from "lodash";

export function createApiTypes(namePrefix, action = "", isWaitingTypeNeeded = true) {
    if (isWaitingTypeNeeded) {
        return [
            `${namePrefix}_${action}_SUCCESS`,
            `${namePrefix}_${action}_FAILURE`,
            `${namePrefix}_${action}_WAITING`,
        ];
    } else {
        return [`${namePrefix}_${action}_SUCCESS`, `${namePrefix}_${action}_FAILURE`];
    }
}
const successHandler = (state, action) => {
    return { ...state, ...action.payload };
};

const failureHandler = (state, action) => {
    return { ...state, errorObject: action.payload, error: action.error };
};

function createApiActionHandlers(types, handlers = []) {
    const [...defaultHandlers] = [successHandler, failureHandler];
    return _.reduce(
        _.zip(types, handlers, defaultHandlers),
        (result, value) => {
            result[value[0]] = value[1] || value[2];
            return result;
        },
        {},
    );
}
export function createCombinedApiHandlers(apiTypes) {
    return _.reduce(
        _.values(apiTypes),
        (prevItem, currItem) => ({ ...prevItem, ...createApiActionHandlers(currItem) }),
        {},
    );
}
export function createReducer(initialState, handlers) {
    return (state = initialState, action) => {
        if (_.isNil(action)) {
            return state;
        }

        const handler = handlers[action.type];
        if (_.isNil(handler)) {
            return state;
        }

        return handler(state, action);
    };
}
