import React, { useMemo } from "react";
import MemberFilter, { convertToLocalDisables } from "../components/MemberFilter";
import _ from "lodash";
import { useAccountPermissionsGetQuery } from "@Shared/features/accounts/accountsApiSlice";
import { useProfileInfoGetQuery } from "@Shared/features/profile/v1/profileApiSlice";
import { MemberFilterProps } from "@Admin/components/ReportPayloadBuilders/ReportPayloadCommonBuilder";
import { useClubGetQuery } from "@Shared/features/clubs/v1/clubsApiSlice";
import { getAccessWithPermission, permissions } from "@Shared/helpers/permissionsHelper";

let MemberFilterBaseContainer = (props: MemberFilterProps) => {
    const { data: clubsResponse } = useClubGetQuery({});

    const clubs = useMemo(() => clubsResponse?.result ?? [], [clubsResponse]);

    return (
        <MemberFilter
            {...props}
            payloads={{
                clubs,
            }}
        />
    );
};

const MemberFilterWithProfilePrefillsContainer = (props: MemberFilterProps) => {
    let disables = convertToLocalDisables(props.disables);
    let filter = props.filter ?? {};

    const { data: profileInfoData } = useProfileInfoGetQuery();
    const profileInfo = useMemo(() => profileInfoData?.result || null, [profileInfoData]);

    const { data: accountPermissionsData } = useAccountPermissionsGetQuery();
    const accountPermissions = useMemo(() => accountPermissionsData?.result || [], [accountPermissionsData]);
    const hasMemberReadPermission = getAccessWithPermission(
        accountPermissions?.map((ap) => ap.permissionId),
        [permissions.memberRead, permissions.memberOrganizationMyRead],
    );
    const hasMemberClubMyReadPermission = getAccessWithPermission(
        accountPermissions?.map((ap) => ap.permissionId),
        [permissions.memberClubMyRead],
    );

    const onlyClub = !_.isNil(profileInfo) && hasMemberReadPermission == false && hasMemberClubMyReadPermission == true;
    const filterByPermission = useMemo(
        () => (onlyClub ? { ...filter, clubId: profileInfo.clubId } : filter),
        [onlyClub, filter],
    );
    const disablesByPermission = useMemo(
        () => (onlyClub ? { ...disables, clubId: true } : disables),
        [onlyClub, disables],
    );

    return <MemberFilterBaseContainer {...props} filter={filterByPermission} disables={disablesByPermission} />;
};

const MemberFilterContainer = MemberFilterWithProfilePrefillsContainer;
export default MemberFilterContainer;
