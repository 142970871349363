import _ from "lodash";
import { CountryId } from "./countries";

type Template = {
    id: number;
    key: string;
    countryId: CountryId;
    localId: number;
    fullName: string;
    shortName: string;
};
const checkRegions = <T extends readonly Template[]>(o: T): T => o;

export const regions = checkRegions([
    { id: 1, key: "minskCity", countryId: 112, localId: 7, fullName: "Город Минск", shortName: "Город Минск" },
    {
        id: 2,
        key: "minskRegion",
        countryId: 112,
        localId: 5,
        fullName: "Минская область",
        shortName: "Минская область",
    },
    { id: 3, key: "brestRegion", countryId: 112, localId: 1, fullName: "Брестская область", shortName: "Брест" },
    { id: 4, key: "gomelRegion", countryId: 112, localId: 3, fullName: "Гомельская область", shortName: "Гомель" },
    { id: 5, key: "grodnoRegion", countryId: 112, localId: 4, fullName: "Гродненская область", shortName: "Гродно" },
    { id: 6, key: "mogilevRegion", countryId: 112, localId: 6, fullName: "Могилевская область", shortName: "Могилев" },
    { id: 7, key: "vitebskRegion", countryId: 112, localId: 2, fullName: "Витебская область", shortName: "Витебск" },

    {
        id: 1121829,
        key: "altaiTerritory",
        countryId: 643,
        localId: 1,
        fullName: "Алтайский край",
        shortName: "Алтайский край",
    },
    {
        id: 1123488,
        key: "amurRegion",
        countryId: 643,
        localId: 10,
        fullName: "Амурская область",
        shortName: "Амурская область",
    },
    {
        id: 1000236,
        key: "arkhangelskRegion",
        countryId: 643,
        localId: 11,
        fullName: "Архангельская область",
        shortName: "Архангельская область",
    },
    {
        id: 1004118,
        key: "astrakhanRegion",
        countryId: 643,
        localId: 12,
        fullName: "Астраханская область",
        shortName: "Астраханская область",
    },
    {
        id: 1009404,
        key: "belgorodRegion",
        countryId: 643,
        localId: 14,
        fullName: "Белгородская область",
        shortName: "Белгородская область",
    },
    {
        id: 1011109,
        key: "bryanskRegion",
        countryId: 643,
        localId: 15,
        fullName: "Брянская область",
        shortName: "Брянская область",
    },
    {
        id: 1124833,
        key: "vladimirRegion",
        countryId: 643,
        localId: 17,
        fullName: "Владимирская область",
        shortName: "Владимирская область",
    },
    {
        id: 1014032,
        key: "volgogradRegion",
        countryId: 643,
        localId: 18,
        fullName: "Волгоградская область",
        shortName: "Волгоградская область",
    },
    {
        id: 1015702,
        key: "vologdaRegion",
        countryId: 643,
        localId: 19,
        fullName: "Вологодская область",
        shortName: "Вологодская область",
    },
    {
        id: 1023816,
        key: "voronezhskayaRegion",
        countryId: 643,
        localId: 20,
        fullName: "Воронежская область",
        shortName: "Воронежская область",
    },
    {
        id: 1127400,
        key: "jewishAutonomousRegion",
        countryId: 643,
        localId: 99,
        fullName: "Еврейская автономная область",
        shortName: "Еврейская автономная область",
    },
    {
        id: 1159987,
        key: "zabaikalskyTerritory",
        countryId: 643,
        localId: 76,
        fullName: "Забайкальский край",
        shortName: "Забайкальский край",
    },
    {
        id: 1027297,
        key: "ivanovskayaRegion",
        countryId: 643,
        localId: 24,
        fullName: "Ивановская область",
        shortName: "Ивановская область",
    },
    {
        id: 1127513,
        key: "irkutskRegion",
        countryId: 643,
        localId: 25,
        fullName: "Иркутская область",
        shortName: "Иркутская область",
    },
    {
        id: 1030428,
        key: "kabardinoBalkarianRepublic",
        countryId: 643,
        localId: 83,
        fullName: "Кабардино-Балкарская Республика",
        shortName: "Кабардино-Балкарская Республика",
    },
    {
        id: 1030632,
        key: "kaliningradRegion",
        countryId: 643,
        localId: 27,
        fullName: "Калининградская область",
        shortName: "Калининградская область",
    },
    {
        id: 1032084,
        key: "kalugaRegion",
        countryId: 643,
        localId: 29,
        fullName: "Калужская область",
        shortName: "Калужская область",
    },
    {
        id: 1128991,
        key: "kamchatkaTerritory",
        countryId: 643,
        localId: 30,
        fullName: "Камчатский край",
        shortName: "Камчатский край",
    },
    {
        id: 1035359,
        key: "karachayCherkessRepublic",
        countryId: 643,
        localId: 91,
        fullName: "Карачаево-Черкесская Республика",
        shortName: "Карачаево-Черкесская Республика",
    },
    {
        id: 1129059,
        key: "kemerovoRegion",
        countryId: 643,
        localId: 32,
        fullName: "Кемеровская область",
        shortName: "Кемеровская область",
    },
    {
        id: 1130218,
        key: "kirovskayaRegion",
        countryId: 643,
        localId: 33,
        fullName: "Кировская область",
        shortName: "Кировская область",
    },
    {
        id: 1037344,
        key: "kostromaRegion",
        countryId: 643,
        localId: 34,
        fullName: "Костромская область",
        shortName: "Костромская область",
    },
    {
        id: 1040652,
        key: "krasnodarTerritory",
        countryId: 643,
        localId: 3,
        fullName: "Краснодарский край",
        shortName: "Краснодарский край",
    },
    {
        id: 1134771,
        key: "krasnoyarskTerritory",
        countryId: 643,
        localId: 4,
        fullName: "Красноярский край",
        shortName: "Красноярский край",
    },
    {
        id: 1137144,
        key: "kurganRegion",
        countryId: 643,
        localId: 37,
        fullName: "Курганская область",
        shortName: "Курганская область",
    },
    {
        id: 1042388,
        key: "kurskRegion",
        countryId: 643,
        localId: 38,
        fullName: "Курская область",
        shortName: "Курская область",
    },
    {
        id: 1045244,
        key: "leningradRegion",
        countryId: 643,
        localId: 41,
        fullName: "Ленинградская область",
        shortName: "Ленинградская область",
    },
    {
        id: 1048584,
        key: "lipetskayaRegion",
        countryId: 643,
        localId: 42,
        fullName: "Липецкая область",
        shortName: "Липецкая область",
    },
    {
        id: 1138434,
        key: "magadanRegion",
        countryId: 643,
        localId: 44,
        fullName: "Магаданская область",
        shortName: "Магаданская область",
    },
    {
        id: 1053480,
        key: "moscowRegion",
        countryId: 643,
        localId: 46,
        fullName: "Московская область",
        shortName: "Московская область",
    },
    {
        id: 1060316,
        key: "murmanskRegion",
        countryId: 643,
        localId: 47,
        fullName: "Мурманская область",
        shortName: "Мурманская область",
    },
    {
        id: 5331184,
        key: "nenetsRegion",
        countryId: 643,
        localId: 111,
        fullName: "Ненецкий автономный округ",
        shortName: "Ненецкий автономный округ",
    },
    {
        id: 1138534,
        key: "nizhnyNovgorodRegion",
        countryId: 643,
        localId: 22,
        fullName: "Нижегородская область",
        shortName: "Нижегородская область",
    },
    {
        id: 1060458,
        key: "novgorodRegion",
        countryId: 643,
        localId: 49,
        fullName: "Новгородская область",
        shortName: "Новгородская область",
    },
    {
        id: 1143518,
        key: "novosibirskRegion",
        countryId: 643,
        localId: 50,
        fullName: "Новосибирская область",
        shortName: "Новосибирская область",
    },
    {
        id: 1145150,
        key: "omskRegion",
        countryId: 643,
        localId: 52,
        fullName: "Омская область",
        shortName: "Омская область",
    },
    {
        id: 1146712,
        key: "orenburgRegion",
        countryId: 643,
        localId: 53,
        fullName: "Оренбургская область",
        shortName: "Оренбургская область",
    },
    {
        id: 1064424,
        key: "orlovskayaRegion",
        countryId: 643,
        localId: 54,
        fullName: "Орловская область",
        shortName: "Орловская область",
    },
    {
        id: 1067455,
        key: "penzaRegion",
        countryId: 643,
        localId: 56,
        fullName: "Пензенская область",
        shortName: "Пензенская область",
    },
    {
        id: 1148549,
        key: "permRegion",
        countryId: 643,
        localId: 57,
        fullName: "Пермский край",
        shortName: "Пермский край",
    },
    {
        id: 1152714,
        key: "primorskyTerritory",
        countryId: 643,
        localId: 5,
        fullName: "Приморский край",
        shortName: "Приморский край",
    },
    {
        id: 1069004,
        key: "pskovRegion",
        countryId: 643,
        localId: 58,
        fullName: "Псковская область",
        shortName: "Псковская область",
    },
    {
        id: 1000001,
        key: "adygeaRepublic",
        countryId: 643,
        localId: 79,
        fullName: "Республика Адыгея",
        shortName: "Республика Адыгея",
    },
    {
        id: 1121540,
        key: "altaiRepublic",
        countryId: 643,
        localId: 84,
        fullName: "Республика Алтай",
        shortName: "Республика Алтай",
    },
    {
        id: 1004565,
        key: "bashkortostanRepublic",
        countryId: 643,
        localId: 80,
        fullName: "Республика Башкортостан",
        shortName: "Республика Башкортостан",
    },
    {
        id: 1124157,
        key: "buryatiaRepublic",
        countryId: 643,
        localId: 81,
        fullName: "Республика Бурятия",
        shortName: "Республика Бурятия",
    },
    {
        id: 1025654,
        key: "dagestanRepublic",
        countryId: 643,
        localId: 82,
        fullName: "Республика Дагестан",
        shortName: "Республика Дагестан",
    },
    {
        id: 1030371,
        key: "ingushetiaRepublic",
        countryId: 643,
        localId: 26,
        fullName: "Республика Ингушетия",
        shortName: "Республика Ингушетия",
    },
    {
        id: 1031793,
        key: "kalmykiaRepublic",
        countryId: 643,
        localId: 85,
        fullName: "Республика Калмыкия",
        shortName: "Республика Калмыкия",
    },
    {
        id: 1035522,
        key: "kareliaRepublic",
        countryId: 643,
        localId: 86,
        fullName: "Республика Карелия",
        shortName: "Республика Карелия",
    },
    {
        id: 1036606,
        key: "komiRepublic",
        countryId: 643,
        localId: 87,
        fullName: "Республика Коми",
        shortName: "Республика Коми",
    },
    {
        id: 1115660,
        key: "crimeaRepublic",
        countryId: 643,
        localId: 35,
        fullName: "Республика Крым",
        shortName: "Республика Крым",
    },
    {
        id: 1050307,
        key: "mariElRepublic",
        countryId: 643,
        localId: 88,
        fullName: "Республика Марий Эл",
        shortName: "Республика Марий Эл",
    },
    {
        id: 1052052,
        key: "mordoviaRepublic",
        countryId: 643,
        localId: 89,
        fullName: "Республика Мордовия",
        shortName: "Республика Мордовия",
    },
    {
        id: 1153366,
        key: "sakhaRepublic",
        countryId: 643,
        localId: 98,
        fullName: "Республика Саха (Якутия)",
        shortName: "Республика Саха (Якутия)",
    },
    {
        id: 1086244,
        key: "northOssetiaAlaniaRepublic",
        countryId: 643,
        localId: 90,
        fullName: "Республика Северная Осетия — Алания",
        shortName: "Республика Северная Осетия — Алания",
    },
    {
        id: 1094197,
        key: "tatarstanRepublic",
        countryId: 643,
        localId: 92,
        fullName: "Республика Татарстан",
        shortName: "Республика Татарстан",
    },
    {
        id: 1157049,
        key: "tyvaRepublic",
        countryId: 643,
        localId: 93,
        fullName: "Республика Тыва",
        shortName: "Республика Тыва",
    },
    {
        id: 1159424,
        key: "khakassiaRepublic",
        countryId: 643,
        localId: 95,
        fullName: "Республика Хакасия",
        shortName: "Республика Хакасия",
    },
    {
        id: 1077676,
        key: "rostovRegion",
        countryId: 643,
        localId: 60,
        fullName: "Ростовская область",
        shortName: "Ростовская область",
    },
    {
        id: 1080077,
        key: "ryazanRegion",
        countryId: 643,
        localId: 61,
        fullName: "Рязанская область",
        shortName: "Рязанская область",
    },
    {
        id: 1082931,
        key: "samaraRegion",
        countryId: 643,
        localId: 36,
        fullName: "Самарская область",
        shortName: "Самарская область",
    },
    {
        id: 1084332,
        key: "saratovRegion",
        countryId: 643,
        localId: 63,
        fullName: "Саратовская область",
        shortName: "Саратовская область",
    },
    {
        id: 1153840,
        key: "sakhalinRegion",
        countryId: 643,
        localId: 64,
        fullName: "Сахалинская область",
        shortName: "Сахалинская область",
    },
    {
        id: 1154131,
        key: "sverdlovskRegion",
        countryId: 643,
        localId: 65,
        fullName: "Свердловская область",
        shortName: "Свердловская область",
    },
    {
        id: 1086468,
        key: "smolenskayaRegion",
        countryId: 643,
        localId: 66,
        fullName: "Смоленская область",
        shortName: "Смоленская область",
    },
    {
        id: 1091406,
        key: "stavropolTerritory",
        countryId: 643,
        localId: 7,
        fullName: "Ставропольский край",
        shortName: "Ставропольский край",
    },
    {
        id: 1092174,
        key: "tambovRegion",
        countryId: 643,
        localId: 68,
        fullName: "Тамбовская область",
        shortName: "Тамбовская область",
    },
    {
        id: 1097508,
        key: "tverskayaRegion",
        countryId: 643,
        localId: 28,
        fullName: "Тверская область",
        shortName: "Тверская область",
    },
    {
        id: 1156388,
        key: "tomskRegion",
        countryId: 643,
        localId: 69,
        fullName: "Томская область",
        shortName: "Томская область",
    },
    {
        id: 1105465,
        key: "tulaRegion",
        countryId: 643,
        localId: 70,
        fullName: "Тульская область",
        shortName: "Тульская область",
    },
    {
        id: 1157218,
        key: "tyumenRegion",
        countryId: 643,
        localId: 71,
        fullName: "Тюменская область",
        shortName: "Тюменская область",
    },
    {
        id: 1109098,
        key: "udmurtRepublic",
        countryId: 643,
        localId: 94,
        fullName: "Удмуртская Республика",
        shortName: "Удмуртская Республика",
    },
    {
        id: 1111137,
        key: "ulyanovskRegion",
        countryId: 643,
        localId: 73,
        fullName: "Ульяновская область",
        shortName: "Ульяновская область",
    },
    {
        id: 1158917,
        key: "khabarovskiTerritory",
        countryId: 643,
        localId: 8,
        fullName: "Хабаровский край",
        shortName: "Хабаровский край",
    },
    {
        id: 1159710,
        key: "khantyMansiyskYugraRegion",
        countryId: 643,
        localId: 71100,
        fullName: "Ханты-Мансийский автономный округ — Югра",
        shortName: "Ханты-Мансийский автономный округ — Югра",
    },
    {
        id: 1112201,
        key: "chelyabinskRegion",
        countryId: 643,
        localId: 75,
        fullName: "Челябинская область",
        shortName: "Челябинская область",
    },
    {
        id: 1113642,
        key: "chechenRepublic",
        countryId: 643,
        localId: 96,
        fullName: "Чеченская Республика",
        shortName: "Чеченская Республика",
    },
    {
        id: 1113937,
        key: "chuvashChuvashiaRepublic",
        countryId: 643,
        localId: 97,
        fullName: "Чувашская Республика — Чувашия",
        shortName: "Чувашская Республика — Чувашия",
    },
    {
        id: 1160844,
        key: "chukchiRegion",
        countryId: 643,
        localId: 77,
        fullName: "Чукотский автономный округ",
        shortName: "Чукотский автономный округ",
    },
    {
        id: 1160930,
        key: "yamaloNenetsRegion",
        countryId: 643,
        localId: 71140,
        fullName: "Ямало-Ненецкий автономный округ",
        shortName: "Ямало-Ненецкий автономный округ",
    },
    {
        id: 1115658,
        key: "yaroslavlRegion",
        countryId: 643,
        localId: 78,
        fullName: "Ярославская область",
        shortName: "Ярославская область",
    },

    {
        id: 1700002,
        key: "akmolinskayaRegion",
        countryId: 398,
        localId: 3,
        fullName: "Акмолинская область",
        shortName: "Акмолинская область",
    },
    {
        id: 1700309,
        key: "aktobeRegion",
        countryId: 398,
        localId: 4,
        fullName: "Актюбинская область",
        shortName: "Актюбинская область",
    },
    {
        id: 1700503,
        key: "almatyRegion",
        countryId: 398,
        localId: 5,
        fullName: "Алматинская область",
        shortName: "Алматинская область",
    },
    {
        id: 1700945,
        key: "atyrauRegion",
        countryId: 398,
        localId: 6,
        fullName: "Атырауская область",
        shortName: "Атырауская область",
    },
    {
        id: 1701029,
        key: "eastKazakhstanRegion",
        countryId: 398,
        localId: 16,
        fullName: "Восточно-Казахстанская область",
        shortName: "Восточно-Казахстанская область",
    },
    {
        id: 1701315,
        key: "zhambylskayaRegion",
        countryId: 398,
        localId: 8,
        fullName: "Жамбылская область",
        shortName: "Жамбылская область",
    },
    {
        id: 1701514,
        key: "westKazakhstanRegion",
        countryId: 398,
        localId: 7,
        fullName: "Западно-Казахстанская область",
        shortName: "Западно-Казахстанская область",
    },
    {
        id: 1701716,
        key: "karagandaRegion",
        countryId: 398,
        localId: 9,
        fullName: "Карагандинская область",
        shortName: "Карагандинская область",
    },
    {
        id: 1701955,
        key: "kostanayRegion",
        countryId: 398,
        localId: 10,
        fullName: "Костанайская область",
        shortName: "Костанайская область",
    },
    {
        id: 1702235,
        key: "kyzylordaRegion",
        countryId: 398,
        localId: 11,
        fullName: "Кызылординская область",
        shortName: "Кызылординская область",
    },
    {
        id: 1702346,
        key: "mangystauRegion",
        countryId: 398,
        localId: 12,
        fullName: "Мангистауская область",
        shortName: "Мангистауская область",
    },
    {
        id: 1702392,
        key: "pavlodarRegion",
        countryId: 398,
        localId: 14,
        fullName: "Павлодарская область",
        shortName: "Павлодарская область",
    },
    {
        id: 1702577,
        key: "northKazakhstanRegion",
        countryId: 398,
        localId: 15,
        fullName: "Северо-Казахстанская область",
        shortName: "Северо-Казахстанская область",
    },
    {
        id: 1702873,
        key: "turkestanRegion",
        countryId: 398,
        localId: 13,
        fullName: "Туркестанская область",
        shortName: "Туркестанская область",
    },

    {
        id: 2200001,
        key: "aragatsotnskayaRegion",
        countryId: 51,
        localId: 1,
        fullName: "Арагацотнская область",
        shortName: "Арагацотнская область",
    },
    {
        id: 2200123,
        key: "araratRegion",
        countryId: 51,
        localId: 2,
        fullName: "Араратская область",
        shortName: "Араратская область",
    },
    {
        id: 2200222,
        key: "armavirRegion",
        countryId: 51,
        localId: 3,
        fullName: "Армавирская область",
        shortName: "Армавирская область",
    },

    {
        id: 2300001,
        key: "batkenRegion",
        countryId: 417,
        localId: 1,
        fullName: "Баткенская область",
        shortName: "Баткенская область",
    },
    {
        id: 2300050,
        key: "jalalAbadskayaRegion",
        countryId: 417,
        localId: 2,
        fullName: "Джалал-Абадская область",
        shortName: "Джалал-Абадская область",
    },
    {
        id: 1703239,
        key: "issykKulRegion",
        countryId: 417,
        localId: 3,
        fullName: "Иссык-Кульская область",
        shortName: "Иссык-Кульская область",
    },
    {
        id: 2300227,
        key: "narynskayaRegion",
        countryId: 417,
        localId: 4,
        fullName: "Нарынская область",
        shortName: "Нарынская область",
    },
    {
        id: 2300290,
        key: "oshRegion",
        countryId: 417,
        localId: 5,
        fullName: "Ошская область",
        shortName: "Ошская область",
    },
    {
        id: 2300445,
        key: "talasskayaRegion",
        countryId: 417,
        localId: 6,
        fullName: "Таласская область",
        shortName: "Таласская область",
    },
    {
        id: 2300474,
        key: "chuiskayaRegion",
        countryId: 417,
        localId: 7,
        fullName: "Чуйская область",
        shortName: "Чуйская область",
    },

    {
        id: 5423497,
        key: "aTOGagauzia",
        countryId: 498,
        localId: 9999,
        fullName: "АТО Гагаузия",
        shortName: "АТО Гагаузия",
    },
    {
        id: 5423652,
        key: "aTOTransnistria",
        countryId: 498,
        localId: 9998,
        fullName: "АТО Приднестровье",
        shortName: "АТО Приднестровье",
    },
    {
        id: 5423445,
        key: "bessarabianDistrict",
        countryId: 498,
        localId: 61,
        fullName: "Бессарабский район",
        shortName: "Бессарабский район",
    },
    {
        id: 5423457,
        key: "brichanskyDistrict",
        countryId: 498,
        localId: 63,
        fullName: "Бричанский район",
        shortName: "Бричанский район",
    },
    {
        id: 5423480,
        key: "glodyanskyDistrict",
        countryId: 498,
        localId: 76,
        fullName: "Глодянский район",
        shortName: "Глодянский район",
    },
    {
        id: 5423474,
        key: "dondyushanskyDistrict",
        countryId: 498,
        localId: 70,
        fullName: "Дондюшанский район",
        shortName: "Дондюшанский район",
    },
    {
        id: 5423475,
        key: "drokievskyDistrict",
        countryId: 498,
        localId: 71,
        fullName: "Дрокиевский район",
        shortName: "Дрокиевский район",
    },
    {
        id: 5423476,
        key: "dubossarskyDistrict",
        countryId: 498,
        localId: 72,
        fullName: "Дубоссарский район",
        shortName: "Дубоссарский район",
    },
    {
        id: 5423477,
        key: "edinetskyDistrict",
        countryId: 498,
        localId: 73,
        fullName: "Единецкий район",
        shortName: "Единецкий район",
    },
    {
        id: 5423461,
        key: "kagulskyDistrict",
        countryId: 498,
        localId: 64,
        fullName: "Кагульский район",
        shortName: "Кагульский район",
    },
    {
        id: 5423467,
        key: "kalarashskyDistrict",
        countryId: 498,
        localId: 66,
        fullName: "Каларашский район",
        shortName: "Каларашский район",
    },
    {
        id: 5423462,
        key: "cantemirskyDistrict",
        countryId: 498,
        localId: 65,
        fullName: "Кантемирский район",
        shortName: "Кантемирский район",
    },
    {
        id: 5423468,
        key: "kaushanskyDistrict",
        countryId: 498,
        localId: 67,
        fullName: "Каушанский район",
        shortName: "Каушанский район",
    },
    {
        id: 5423473,
        key: "kriulianskyDistrict",
        countryId: 498,
        localId: 9997,
        fullName: "Криулянский район",
        shortName: "Криулянский район",
    },
    {
        id: 5423483,
        key: "leovskyDistrict",
        countryId: 498,
        localId: 79,
        fullName: "Леовский район",
        shortName: "Леовский район",
    },
    {
        id: 5423484,
        key: "nisporenskyDistrict",
        countryId: 498,
        localId: 80,
        fullName: "Ниспоренский район",
        shortName: "Ниспоренский район",
    },
    {
        id: 5423444,
        key: "novoanninskyDistrict",
        countryId: 498,
        localId: 9996,
        fullName: "Новоаненский район",
        shortName: "Новоаненский район",
    },
    {
        id: 5423485,
        key: "oknitskyDistrict",
        countryId: 498,
        localId: 81,
        fullName: "Окницкий район",
        shortName: "Окницкий район",
    },
    {
        id: 5423486,
        key: "orheevskyDistrict",
        countryId: 498,
        localId: 82,
        fullName: "Оргеевский район",
        shortName: "Оргеевский район",
    },
    {
        id: 5423487,
        key: "rezinskyDistrict",
        countryId: 498,
        localId: 5400,
        fullName: "Резинский район",
        shortName: "Резинский район",
    },
    {
        id: 5423488,
        key: "ryshkanskyDistrict",
        countryId: 498,
        localId: 84,
        fullName: "Рышканский район",
        shortName: "Рышканский район",
    },
    {
        id: 5423490,
        key: "sorokskyDistrict",
        countryId: 498,
        localId: 87,
        fullName: "Сорокский район",
        shortName: "Сорокский район",
    },
    {
        id: 5423491,
        key: "strashenskyDistrict",
        countryId: 498,
        localId: 89,
        fullName: "Страшенский район",
        shortName: "Страшенский район",
    },
    {
        id: 5423489,
        key: "syngereyskyDistrict",
        countryId: 498,
        localId: 85,
        fullName: "Сынджерейский район",
        shortName: "Сынджерейский район",
    },
    {
        id: 5423494,
        key: "taraclianDistrict",
        countryId: 498,
        localId: 90,
        fullName: "Тараклийский район",
        shortName: "Тараклийский район",
    },
    {
        id: 5423495,
        key: "telenestiDistrict",
        countryId: 498,
        localId: 91,
        fullName: "Теленештский район",
        shortName: "Теленештский район",
    },
    {
        id: 5423496,
        key: "ungenskyDistrict",
        countryId: 498,
        localId: 92,
        fullName: "Унгенский район",
        shortName: "Унгенский район",
    },
    {
        id: 5423478,
        key: "falestiDistrict",
        countryId: 498,
        localId: 74,
        fullName: "Фалештский район",
        shortName: "Фалештский район",
    },
    {
        id: 5423479,
        key: "florestiDistrict",
        countryId: 498,
        localId: 75,
        fullName: "Флорештский район",
        shortName: "Флорештский район",
    },
    {
        id: 5423481,
        key: "hincestiDistrict",
        countryId: 498,
        localId: 77,
        fullName: "Хынчештский район",
        shortName: "Хынчештский район",
    },
    {
        id: 5423472,
        key: "chimishliDistrict",
        countryId: 498,
        localId: 68,
        fullName: "Чимишлийский район",
        shortName: "Чимишлийский район",
    },
    {
        id: 5423492,
        key: "szoldanestiDistrict",
        countryId: 498,
        localId: 86,
        fullName: "Шолданештский район",
        shortName: "Шолданештский район",
    },
    {
        id: 5423493,
        key: "stefanVodskyDistrict",
        countryId: 498,
        localId: 88,
        fullName: "Штефан-Водский район",
        shortName: "Штефан-Водский район",
    },
    {
        id: 5423482,
        key: "yalovenskyDistrict",
        countryId: 498,
        localId: 78,
        fullName: "Яловенский район",
        shortName: "Яловенский район",
    },

    {
        id: 2100001,
        key: "andijanRegion",
        countryId: 860,
        localId: 1,
        fullName: "Андижанская область",
        shortName: "Андижанская область",
    },
    {
        id: 2100224,
        key: "bukharaRegion",
        countryId: 860,
        localId: 2,
        fullName: "Бухарская область",
        shortName: "Бухарская область",
    },
    {
        id: 2100367,
        key: "jizzakhRegion",
        countryId: 860,
        localId: 3,
        fullName: "Джизакская область",
        shortName: "Джизакская область",
    },
    {
        id: 2100516,
        key: "kashkadaryaRegion",
        countryId: 860,
        localId: 4,
        fullName: "Кашкадарьинская область",
        shortName: "Кашкадарьинская область",
    },

    {
        id: 5471343,
        key: "gornoBadakhshanAutonomousRegion",
        countryId: 762,
        localId: 1,
        fullName: "Горно-Бадахшанская автономная область",
        shortName: "Горно-Бадахшанская автономная область",
    },
    {
        id: 5471344,
        key: "sogdianRegion",
        countryId: 762,
        localId: 3,
        fullName: "Согдийская область",
        shortName: "Согдийская область",
    },
    {
        id: 5471345,
        key: "khatlonRegion",
        countryId: 762,
        localId: 2,
        fullName: "Хатлонская область",
        shortName: "Хатлонская область",
    },
] as const);

export type Regions = typeof regions;
export type Region = Regions[number];
export type RegionId = Region["id"];
export type RegionKey = Region["key"];
export type RegionById<TId extends RegionId> = Extract<Region, { id: TId }>;
export type RegionByKey<TKey extends RegionKey> = Extract<Region, { key: TKey }>;
export type RegionIds = { [key in RegionKey]: RegionByKey<key>["id"] };

export type RegionCountryId = Region["countryId"];
export type RegionLocalId = Region["localId"];
export type RegionLocalIdByCountryId<TCountryId extends RegionCountryId> = Extract<
    Region,
    { countryId: TCountryId }
>["localId"];
export type RegionByCountryIdAndLocalId<TCountryId extends RegionCountryId, TLocalId extends RegionLocalId> = Extract<
    Region,
    { countryId: TCountryId; localId: TLocalId }
>;

export const regionIds = _.reduce(regions, (r, g) => ({ ...r, [g.key]: g.id }), {}) as RegionIds;

export const isRegionId = (value: any): value is RegionId => regions.some((t) => t.id === value);

export const isRegionKey = (value: any): value is RegionKey => regions.some((t) => t.key === value);

export const getRegionByKey = <TKey extends RegionKey>(key: TKey) =>
    regions.find((t) => t.key === key) as RegionByKey<TKey>;

export const getRegionById = <TId extends RegionId>(id: TId) => regions.find((r) => r.id === id) as RegionById<TId>;

export const getRegionByPair = <TCountryId extends RegionCountryId, TLocalId extends RegionLocalId>(
    countryId: TCountryId,
    localId: TLocalId,
) =>
    regions.find((r) => r.countryId === countryId && r.localId === localId) as RegionByCountryIdAndLocalId<
        TCountryId,
        TLocalId
    >;
