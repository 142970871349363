import { apiSlice } from "@Shared/app/api/apiSlice";
import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { memberSelectors } from "~/Admin/helpers/selectors";
import { location } from "./SharedActions";

export const apiTypes = {
    loadRestoreByPersonalNumberData: createApiTypes("RESTORE_BY_PERSONAL_NUMBER_DATA", "LOAD", false),
    loadRestoreByCommonInfoData: createApiTypes("RESTORE_BY_COMMON_INFO_DATA", "LOAD", false),
    reservePersonalNumberType: createApiTypes("PERSONAL_NUMBER", "RESERVE", false),
    refreshPersonalNumberType: createApiTypes("PERSONAL_NUMBER", "REFRESH", false),
    saveMemberDataType: createApiTypes("MEMBER_DATA", "SET"),
    generateMembersCertificates: createApiTypes("MEMBERS_CERTIFICATES", "POST"),
    updateGenerationProcessData: createApiTypes("MEMBER_GENERATION_PROCESS", "GET"),
    cancellGenerationProcess: createApiTypes("MEMBER_GENERATION_PROCESS_CANCELL", "GET"),
};

export const types = {
    MEMBER_MERGE: "MEMBER_MERGE",
    RESET_MEMBER_LIST: "RESET_MEMBER_LIST",
    RESET_FULL_MEMBER_LIST: "RESET_FULL_MEMBER_LIST",
    RESET_RESERVED_PERSONAL_NUMBERS: "RESET_RESERVED_PERSONAL_NUMBERS",
    RESET_RESTORE_BY_PERSONAL_NUMBER_DATA: "RESET_RESTORE_BY_PERSONAL_NUMBER_DATA",
    RESET_RESTORE_BY_COMMON_INFO_DATA: "RESET_RESTORE_BY_COMMON_INFO_DATA",
    UPDATE_MEMBER_LIST: "UPDATE_MEMBER_LIST",
    RESET_MEMBER_DATA: "RESET_MEMBER_DATA",
    RESET_CURRENT_GENERATION_PROCESS_TOKEN: "RESET_CURRENT_GENERATION_PROCESS_TOKEN",
    RESET_GENERATION_PROCESS_DATA: "RESET_GENERATION_PROCESS_DATA",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const memberReducer = createReducer(
    {
        generationProcessToken: null,
        generationProcessData: null,
    },
    {
        ...apiHandlers,
        [types.MEMBER_MERGE]: ({ memberData, ...state }, action) => {
            return {
                ...state,
                memberData: {
                    ...memberData,
                    ...action.payload,
                },
            };
        },
        [types.RESET_RESTORE_BY_PERSONAL_NUMBER_DATA]: (state) => ({
            ...state,
            memberRestoreByPersonalNumberData: null,
        }),
        [types.RESET_RESTORE_BY_COMMON_INFO_DATA]: (state) => ({ ...state, memberRestoreByCommonInfoData: null }),
        [types.RESET_MEMBER_DATA]: (state) => ({ ...state, memberData: null }),
        [types.RESET_MEMBER_LIST]: (state) => ({ ...state, memberList: null }),
        [types.RESET_RESERVED_PERSONAL_NUMBERS]: (state) => ({ ...state, reservedPersonalNumbers: null }),
        [types.UPDATE_MEMBER_LIST]: ({ memberList, ...state }, action) => {
            const { metadataPhoto, address, businessRoles, ...data } = action.payload;
            return {
                ...state,
                memberList: [data, ...memberList],
            };
        },
        [types.RESET_CURRENT_GENERATION_PROCESS_TOKEN]: (state) => ({ ...state, generationProcessToken: null }),
        [types.RESET_GENERATION_PROCESS_DATA]: (state) => ({ ...state, generationProcessData: null }),
    },
);

const resetMemberList = () => (dispatch) => {
    dispatch({
        type: types.RESET_MEMBER_LIST,
    });
};

const resetGenerationProcessToken = () => (dispatch) => {
    dispatch({
        type: types.RESET_CURRENT_GENERATION_PROCESS_TOKEN,
    });
};

const resetGenerationProcessData = () => (dispatch) => {
    dispatch({
        type: types.RESET_GENERATION_PROCESS_DATA,
    });
};

const resetReservedPersonalNumbers = () => (dispatch) => {
    dispatch({
        type: types.RESET_RESERVED_PERSONAL_NUMBERS,
    });
};

const reservePersonalNumber = (data) => (dispatch) => {
    return dispatch({
        types: apiTypes.reservePersonalNumberType,
        callAPI: () => proxy.reservePersonalNumberPost(data),
        payload: (state, { result }) => {
            if (!result) {
                dispatch(resetReservedPersonalNumbers());
                return;
            }

            let reservedPersonalNumbers = memberSelectors.getReservedPersonalNumbers(state) ?? [];
            reservedPersonalNumbers = reservedPersonalNumbers.filter(
                (personalNumber) =>
                    +personalNumber.index !== +result.index && +personalNumber.regionId !== +result.regionId,
            );

            return {
                reservedPersonalNumbers: [...reservedPersonalNumbers, result],
            };
        },
    });
};

const refreshPersonalNumber = (data) => (dispatch) => {
    return dispatch({
        types: apiTypes.refreshPersonalNumberType,
        callAPI: () => proxy.refreshPersonalNumberPost(data),
        payload: (state, { result }) => {
            if (!result) {
                dispatch(resetReservedPersonalNumbers());
                return;
            }

            let reservedPersonalNumbers = memberSelectors.getReservedPersonalNumbers(state) ?? [];
            reservedPersonalNumbers = reservedPersonalNumbers.filter(
                (personalNumber) =>
                    +personalNumber.index !== +result.index && +personalNumber.regionId !== +result.regionId,
            );

            return {
                reservedPersonalNumbers: [...reservedPersonalNumbers, result],
            };
        },
    });
};

const resetRestoreByPersonalNumberData = () => (dispatch) => {
    dispatch({
        type: types.RESET_RESTORE_BY_PERSONAL_NUMBER_DATA,
    });
};

const resetRestoreByCommonInfoData = () => (dispatch) => {
    dispatch({
        type: types.RESET_RESTORE_BY_COMMON_INFO_DATA,
    });
};

const getRestoreByPersonalNumberData = (personalNumber) => (dispatch) => {
    dispatch({
        types: apiTypes.loadRestoreByPersonalNumberData,
        callAPI: () => proxy.membersGet({ personalNumberEquals: personalNumber }),
        isLastRequestCheck: true,
        payload: (state, { result }) => {
            return {
                restoreByPersonalNumberData: result.map((member) => ({
                    ...member,
                    doB: new Date(member.doB),
                })),
            };
        },
    });
};
const getRestoreByCommonInfoData = (filter) => (dispatch) => {
    dispatch({
        types: apiTypes.loadRestoreByCommonInfoData,
        callAPI: () => proxy.membersGet(filter),
        isLastRequestCheck: true,
        payload: async (state, { result }) => {
            let mappedMembers = [];
            for (const memberKey in result) {
                const member = result[memberKey];

                let canBeRestored;
                if (!_.isEmpty(member.personalNumber)) {
                    const activeMembersResponse = await proxy.membersGet({
                        personalNumberEquals: member.personalNumber,
                        isDeleted: false,
                    });
                    const activeMembersResponseJson = await activeMembersResponse.json();
                    const activeMembers = activeMembersResponseJson.result;
                    canBeRestored = _.isEmpty(activeMembers);
                } else {
                    const activeMembersResponse = await proxy.membersGet({ id: member.id, isDeleted: false });
                    const activeMembersResponseJson = await activeMembersResponse.json();
                    const activeMembers = activeMembersResponseJson.result;
                    canBeRestored = _.isEmpty(activeMembers);
                }

                const mappedMember = {
                    ...member,
                    canBeRestored,
                    doB: new Date(member.doB),
                };

                mappedMembers = [...mappedMembers, mappedMember];
            }

            return {
                restoreByCommonInfoData: mappedMembers,
            };
        },
    });
};

const deleteMember = (id) => (dispatch) => {
    dispatch({
        types: apiTypes.saveMemberDataType,
        callAPI: () => proxy.memberDelete(id),
        payload: (state, { result }) => {
            if (result) {
                dispatch(apiSlice.util.invalidateTags(["Members"]));
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `Участник успешно удален`,
                        type: `success`,
                    },
                });
                dispatch(location.push(`/members`));
            } else {
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `При удалении участника произошла ошибка`,
                        type: `error`,
                    },
                });
            }
        },
    });
};

const activateMember = (id) => (dispatch) => {
    dispatch({
        types: apiTypes.saveMemberDataType,
        callAPI: () => proxy.memberActivate(id),
        payload: (state, { result }) => {
            if (result) {
                dispatch(apiSlice.util.invalidateTags(["Members"]));
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `Участник успешно восстановлен`,
                        type: `success`,
                    },
                });
                dispatch(location.push(`/members`));
            } else {
                dispatch({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `При восстановлении участника произошла ошибка`,
                        type: `error`,
                    },
                });
            }
        },
    });
};

const generateMembersCertificates = (membersIds) => (dispatch) => {
    dispatch({
        types: apiTypes.generateMembersCertificates,
        callAPI: () => proxy.generateMembersCertificates(membersIds),
        payload: (state, response) => {
            if (response.statusCode === 200) {
                return { generationProcessToken: response.result.token };
            }
        },
    });
};

const updateGenerationProcessData = (token) => (dispatch) => {
    dispatch({
        types: apiTypes.updateGenerationProcessData,
        callAPI: () => proxy.updateGenerationProcessData(token),
        payload: (state, response) => {
            return { generationProcessData: response.result };
        },
    });
};

const cancellGenerationProcess = (token) => (dispatch) => {
    dispatch({
        types: apiTypes.cancellGenerationProcess,
        callAPI: () => proxy.cancellGenerationProcess(token),
        payload: (state, response) => {
            return;
        },
    });
};

export const memberActions = {
    reservePersonalNumber,
    refreshPersonalNumber,
    resetReservedPersonalNumbers,
    resetMemberList,
    deleteMember,
    activateMember,
    getRestoreByPersonalNumberData,
    getRestoreByCommonInfoData,
    resetRestoreByPersonalNumberData,
    resetRestoreByCommonInfoData,
    generateMembersCertificates,
    updateGenerationProcessData,
    cancellGenerationProcess,
    resetGenerationProcessToken,
    resetGenerationProcessData,
};
