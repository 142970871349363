import { proxy } from "~/Admin/core/api";
import _ from "lodash";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { tournamentGroupSelectors } from "../../helpers/selectors";
import { formatArray } from "../../../Shared/helpers/formatters";
import { parseApiModels } from "../../../Shared/helpers/tournamentGroup";

export const apiTypes = {
    loadTournamentGroupListType: createApiTypes("TOURNAMENT_GROUP", "LOAD", false),
};

export const types = {
    RESET_TOURNAMENT_GROUP_CACHE: "RESET_TOURNAMENT_GROUP_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentGroupReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_GROUP_CACHE]: (state) => ({ ...state, cache: [] }),
    },
);

const getTournamentGroupList = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentGroupListType,
        callAPI: () => proxy.tournamentGroupGet(filter),
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentGroupSelectors.getTournamentGroupCache(state)).filter(
                    (pair) => !_.isEqual(pair.filter, filter),
                ),
                {
                    filter,
                    items: parseApiModels(result),
                },
            ],
        }),
    });
};

const resetTournamentGroupCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_GROUP_CACHE,
    });
};

export const tournamentGroupActions = {
    getTournamentGroupList,
    resetTournamentGroupCache,
};
