import _ from "lodash";

export const levels = [
    { id: 0, key: "empty", name: "Нет разряда" },
    { id: 1, key: "youngThird", name: "3 Юношеский разряд" },
    { id: 2, key: "youngSecond", name: "2 Юношеский разряд" },
    { id: 3, key: "youngFirst", name: "1 Юношеский разряд" },
    { id: 4, key: "second", name: "2 Разряд" },
    { id: 5, key: "first", name: "1 Разряд" },
    { id: 6, key: "CMS", name: "КМС" },
    { id: 7, key: "MS", name: "МС" },
    { id: 8, key: "MSIC", name: "МСМК" },
] as const;

export type Levels = typeof levels;
export type Level = Levels[number];
export type LevelId = Level["id"];
export type LevelKey = Level["key"];
export type LevelById<TId extends LevelId> = Extract<Level, { id: TId }>;
export type LevelByKey<TKey extends LevelKey> = Extract<Level, { key: TKey }>;
export type LevelIds = { [key in LevelKey]: LevelByKey<key>["id"] };

export const levelIds = _.reduce(levels, (r, g) => ({ ...r, [g.key]: g.id }), {}) as LevelIds;

export const isLevelId = (value: any): value is LevelId => levels.some((t) => t.id === value);

export const isLevelKey = (value: any): value is LevelKey => levels.some((t) => t.key === value);

export const getLevelByKey = <TKey extends LevelKey>(key: TKey) =>
    levels.find((t) => t.key === key) as LevelByKey<TKey>;

export const getLevelById = <TId extends LevelId>(id: TId) => levels.find((r) => r.id === id) as LevelById<TId>;

export const getCurrentLevelId = (current: number | undefined, last: number | undefined, isCurrent = false) => {
    if (current && !last) {
        return current;
    }

    if (last) {
        if (current) {
            return isCurrent ? current : last;
        }

        return last;
    }

    return undefined;
};
