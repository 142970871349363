import _ from "lodash";
import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { tournamentAttachmentSelectors } from "../../helpers/selectors";
import { formatArray } from "../../../Shared/helpers/formatters";

export const apiTypes = {
    loadTournamentAttachmentListType: createApiTypes("TOURNAMENT_ATTACHMENT", "LOAD", false),
    loadTournamentAttachmentDataType: createApiTypes("TOURNAMENT_ATTACHMENT_DATA", "LOAD", false),
    addTournamentAttachmentType: createApiTypes("TOURNAMENT_ATTACHMENT", "ADD", false),
    removeTournamentAttachmentType: createApiTypes("TOURNAMENT_ATTACHMENT", "REMOVE", false),
};

export const types = {
    RESET_TOURNAMENT_ATTACHMENT_CACHE: "RESET_TOURNAMENT_ATTACHMENT_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentAttachmentReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_ATTACHMENT_CACHE]: (state) => ({ ...state, cache: [] }),
    },
);

const getTournamentAttachmentList = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentAttachmentListType,
        callAPI: () => proxy.tournamentAttachmentGet(filter),
        isLastRequestCheck: true,
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentAttachmentSelectors.getTournamentAttachmentCache(state)).filter(
                    (pair) => !_.isEqual(pair.filter, filter),
                ),
                {
                    filter,
                    items: result,
                },
            ],
        }),
    });
};

const getTournamentAttachmentData = (id) => (dispatch) => {
    return dispatch({
        isServerResponse: true,
        types: apiTypes.loadTournamentAttachmentDataType,
        callAPI: () => proxy.tournamentAttachmentGetData(id),
        payload: async (state, response) => {
            if (response.status === 200) {
                const disposition = response.headers.get("Content-Disposition");
                const filename = disposition.match(/filename=['"]?([^;\n'"]*)['"]?/)[1];

                const blob = await response.blob();

                let link = document.createElement("a");
                link.download = filename;
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },
    });
};

const resetTournamentAttachmentCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_ATTACHMENT_CACHE,
    });
};

const addTournamentAttachment = (tournamentAttachment) => (dispatch) => {
    return dispatch({
        types: apiTypes.addTournamentAttachmentType,
        callAPI: () => proxy.tournamentAttachmentCreate(tournamentAttachment),
        payload: () => {
            dispatch(resetTournamentAttachmentCache());
        },
    });
};

const removeTournamentAttachment = (id) => (dispatch) => {
    return dispatch({
        types: apiTypes.removeTournamentAttachmentType,
        callAPI: () => proxy.tournamentAttachmentDelete(id),
        payload: () => {
            dispatch(resetTournamentAttachmentCache());
        },
    });
};

export const tournamentAttachmentActions = {
    getTournamentAttachmentList,
    getTournamentAttachmentData,
    resetTournamentAttachmentCache,
    addTournamentAttachment,
    removeTournamentAttachment,
};
