export const CALL_HISTORY = "CALL_HISTORY";

function historyMiddleware(history) {
    return ({}) =>
        (next) =>
        (action) => {
            if (action.type !== CALL_HISTORY) {
                return next(action);
            }

            const {
                payload: { method, args },
            } = action;
            history[method](...args);

            return next(action);
        };
}

export default historyMiddleware;

function updateLocation(method) {
    return (...args) => ({
        type: CALL_HISTORY,
        payload: { method, args },
    });
}

export const nav = {
    push: updateLocation("push"),
    replace: updateLocation("replace"),
    go: updateLocation("go"),
    goBack: updateLocation("goBack"),
    goForward: updateLocation("goForward"),
};
