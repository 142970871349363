import React from "react";
import styles from "../LoginContainer.module.scss";
import Input from "@Admin/components/Input/Input";
import Button, { ButtonVariant } from "@Shared/components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { isNil } from "lodash";
import { emailMaxLength, getTextFieldValidation, newerEmailRegExp } from "@Shared/helpers/validation";

interface RestorePasswordFormFields {
    email: string | null;
}

interface RestorePasswordFormProps {
    isLoading: boolean;
    authMessage?: string;
    authError?: string | null;
    restoreAction?: (data: any) => (dispatch: any) => void;
    resetAuthError: () => (dispatch: any) => void;
}

const RestorePasswordForm = (props: RestorePasswordFormProps) => {
    const { isLoading, restoreAction, authError, authMessage, resetAuthError } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RestorePasswordFormFields>({
        mode: "all",
        defaultValues: { email: null },
    });

    const onRestorePasswordSubmit: SubmitHandler<RestorePasswordFormFields> = (data: RestorePasswordFormFields) => {
        resetAuthError?.();
        navigate("/login");
        restoreAction?.({ email: data.email });
    };

    return (
        <>
            <div className={styles.formHeader}>
                <h2 className={styles.title}>{t("auth.restorePassword.passwordRestore")}</h2>
            </div>
            <Input
                placeholder={t("auth.restorePassword.email")}
                name="email"
                inputProps={{
                    ...register("email", {
                        ...getTextFieldValidation(true, newerEmailRegExp, 3, emailMaxLength),
                    }),
                }}
                error={!isNil(errors.email)}
                errorText={errors.email?.message}
            />
            {(!isNil(authError) || !isNil(authMessage)) && (
                <p className={!isNil(authError) ? styles.authError : styles.authMessage}>{authError || authMessage}</p>
            )}
            <div className={styles.footerBtnRow}>
                <Button
                    buttonVariant={ButtonVariant.Outlined}
                    onClick={() => navigate("/login")}
                    title={t("auth.restorePassword.back")}
                />
                <Button
                    buttonVariant={ButtonVariant.Blue}
                    type="submit"
                    disabled={isLoading}
                    title={t("auth.restorePassword.restore")}
                    onClick={handleSubmit(onRestorePasswordSubmit)}
                />
            </div>
        </>
    );
};

export default RestorePasswordForm;
