import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    retry,
} from "@reduxjs/toolkit/query/react";
import { getAuthorization } from "../../helpers/api";
import { RetryOptions } from "@reduxjs/toolkit/dist/query/retry";

const baseQuery = fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers) => {
        const token = getAuthorization();
        if (token) {
            headers.set("authorization", `${token}`);
        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

const baseQueryWithRedirectionToLogin: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    RetryOptions,
    FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
    const result = await baseQueryWithRetry(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        api.dispatch(apiSlice.util.invalidateTags(["Check", "Permissions"]));
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: "api",
    tagTypes: [
        "Accounts",
        "Permissions",
        "Members",
        "ForeignMembers",
        "Profile",
        "Clubs",
        "ForeignClubs",
        "OrganizationClubs",
        "OrganizationMembers",
        "Tournaments",
        "MotionList",
        "MotionData",
        "ForeignMemberCoaches",
        "Trainers",
        "TournamentMembers",
        "Divisions",
        "Attachments",
        "TournamentNodes",
        "Check",
    ],
    baseQuery: baseQueryWithRedirectionToLogin,
    endpoints: () => ({}),
});
