//@ts-check

import _ from "lodash";
import React from "react";
import Flag from "../components/Flag";

/**
   @template {{[k: string]: {
      id: number,
      key: keyof T,
      fullName: string,
      shortName: string,
      code2Chars: string,
      code3Chars: string,
      callingCode: string,
      flagRender: (props: any) => JSX.Element,
   }}} T
   @param {T} o 
   @returns {T}
*/
const checkCountries = (o) => o;

/**
   @template {{ code2Chars: string, [k: string | number | symbol]: any }} T
   @param {T} country 
   @returns { T & { flagRender: (props: any) => JSX.Element } }
*/
const addFlagRender = (country) => ({
    ...country,
    flagRender: (props) =>
        Flag({
            name: country.code2Chars,
            ...props,
        }),
});

export const countries = checkCountries(
    /**@type {const}*/ ({
        AZE: addFlagRender(
            /**@type {const}*/ ({
                id: 31,
                key: "AZE",
                fullName: "Республика Азербайджан",
                shortName: "Азербайджан",
                code2Chars: "AZ",
                code3Chars: "AZE",
                callingCode: "+994",
            }),
        ),

        ARM: addFlagRender(
            /**@type {const}*/ ({
                id: 51,
                key: "ARM",
                fullName: "Республика Армения",
                shortName: "Армения",
                code2Chars: "AM",
                code3Chars: "ARM",
                callingCode: "+374",
            }),
        ),

        BLR: addFlagRender(
            /**@type {const}*/ ({
                id: 112,
                key: "BLR",
                fullName: "Республика Беларусь",
                shortName: "Беларусь",
                code2Chars: "BY",
                code3Chars: "BLR",
                callingCode: "+375",
            }),
        ),

        KAZ: addFlagRender(
            /**@type {const}*/ ({
                id: 398,
                key: "KAZ",
                fullName: "Республика Казахстан",
                shortName: "Казахстан",
                code2Chars: "KZ",
                code3Chars: "KAZ",
                callingCode: "+7",
            }),
        ),

        KGZ: addFlagRender(
            /**@type {const}*/ ({
                id: 417,
                key: "KGZ",
                fullName: "Киргизская Республика",
                shortName: "Киргизия",
                code2Chars: "KG",
                code3Chars: "KGZ",
                callingCode: "+996",
            }),
        ),

        MDA: addFlagRender(
            /**@type {const}*/ ({
                id: 498,
                key: "MDA",
                fullName: "Республика Молдова",
                shortName: "Молдова",
                code2Chars: "MD",
                code3Chars: "MDA",
                callingCode: "+373",
            }),
        ),

        RUS: addFlagRender(
            /**@type {const}*/ ({
                id: 643,
                key: "RUS",
                fullName: "Российская Федерация",
                shortName: "Россия",
                code2Chars: "RU",
                code3Chars: "RUS",
                callingCode: "+7",
            }),
        ),

        TJK: addFlagRender(
            /**@type {const}*/ ({
                id: 762,
                key: "TJK",
                fullName: "Республика Таджикистан",
                shortName: "Таджикистан",
                code2Chars: "TJ",
                code3Chars: "TJK",
                callingCode: "+992",
            }),
        ),

        //UKR: addFlagRender(/**@type {const}*/ ({
        //   id: 804,
        //   key: "UKR",
        //   fullName: "Украина",
        //   shortName: "Украина",
        //   code2Chars: "UA", code3Chars: "UKR"
        //})),

        UZB: addFlagRender(
            /**@type {const}*/ ({
                id: 860,
                key: "UZB",
                fullName: "Республика Узбекистан",
                shortName: "Узбекистан",
                code2Chars: "UZ",
                code3Chars: "UZB",
                callingCode: "+998",
            }),
        ),
    }),
);

/**
   @typedef {countries[keyof countries]} Country
   @typedef {Country["id"]} CountryId
   @typedef {Country["key"]} CountryKey
   @typedef {{[k in CountryKey]: countries[k]["id"]}} CountryIds
*/

export const countryIds = /**@type {CountryIds}*/ (_.mapValues(countries, (v) => v.id));

export const nationalCodes = ["29", "33", "44", "25"];

export const getCountry = (/**@type {CountryId | CountryKey | undefined | null}*/ idOrKey) =>
    _.isString(idOrKey)
        ? _.values(countries).find((c) => c.key === idOrKey)
        : _.isFinite(idOrKey)
          ? _.values(countries).find((c) => c.id === idOrKey)
          : undefined;
