//@ts-check

import _ from "lodash";
import ReportPayloadCommonBuilderContainer from "../views/ReportContainer/ReportPayloadBuilderContainers/ReportPayloadCommonBuilderContainer";

/**
   @typedef {import("../components/ReportPayloadBuilders/ReportPayloadCommonBuilder").ReportPayloadCommonBuilderProps} ReportPayloadCommonBuilderProps
 */

/**
   @typedef {{
      id?: number | null,
      gender?: number | null,
      rankId?: number | null,
      levelId?: number | null,
      clubId?: number | null,
      regionId?: number | null,
      isDeleted?: boolean | null,
      member?: string | null,
      firstNameEquals?: string | null,
      patronymNameEquals?: string | null,
      lastNameEquals?: string | null,
      dayOfBirth?: string | null,
      personalNumber?: string | null,
      personalNumberEquals?: string | null,
      includeMembers?: number[] | null,
      businessRoles?: number[] | null,
   }} MemberFilter

   @typedef {{
      memberFilter?: MemberFilter
   }} ReportPayloadWithMemberFilter
*/

/**
   @typedef {|
      ReportPayloadWithMemberFilter
   } ReportCommonPayload

   @typedef {{
      payload?: ReportCommonPayload, 
      onPayloadChange?: (payload: ReportCommonPayload) => void,
      onCanGenerateReportChange?: (value: boolean) => void
   }} ReportPayloadRenderProps

   @typedef {(props: ReportPayloadRenderProps) => JSX.Element} ReportPayloadRender
*/

/**
   @template {{[k: string]: {
      id: number,
      key: keyof T,
      payloadRender: (props: ReportPayloadRenderProps) => JSX.Element
   }}} T
   @param {T} o 
   @returns {T}
*/
const checkReportTypes = (o) => o;

export const reportTypes = checkReportTypes(
    /**@type {const}*/ ({
        membersCountInRegions: {
            id: 1,
            key: "membersCountInRegions",
            payloadRender: (props) =>
                ReportPayloadCommonBuilderContainer(
                    _.defaults(
                        {},
                        props,
                        /**@type {ReportPayloadCommonBuilderProps}*/ ({
                            header: "Фильтрация участников",
                            showMemberFilter: true,
                        }),
                    ),
                ),
        },
        membersCountInClubs: {
            id: 2,
            key: "membersCountInClubs",
            payloadRender: (props) =>
                ReportPayloadCommonBuilderContainer(
                    _.defaults(
                        {},
                        props,
                        /**@type {ReportPayloadCommonBuilderProps}*/ ({
                            header: "Фильтрация участников",
                            showMemberFilter: true,
                        }),
                    ),
                ),
        },
        membersInClub: {
            id: 3,
            key: "membersInClub",
            payloadRender: (props) =>
                ReportPayloadCommonBuilderContainer(
                    _.defaults(
                        {},
                        props,
                        /**@type {ReportPayloadCommonBuilderProps}*/ ({
                            header: "Фильтрация участников и выбор спортивной организации",
                            showMemberFilter: true,
                            memberFilterProps: {
                                required: {
                                    clubId: true,
                                },
                                hideNoClubOption: true,
                            },
                        }),
                    ),
                ),
        },
        membersInRegion: {
            id: 4,
            key: "membersInRegion",
            payloadRender: (props) =>
                ReportPayloadCommonBuilderContainer(
                    _.defaults(
                        {},
                        props,
                        /**@type {ReportPayloadCommonBuilderProps}*/ ({
                            header: "Фильтрация участников и выбор региона",
                            showMemberFilter: true,
                            memberFilterProps: {
                                required: {
                                    regionId: true,
                                },
                            },
                        }),
                    ),
                ),
        },
    }),
);

/**
   @typedef {reportTypes[keyof reportTypes]["id"]} ReportTypeId
   @typedef {reportTypes[keyof reportTypes]["key"]} ReportTypeKey
   @typedef {{ [k in ReportTypeKey]: reportTypes[k]["id"]}} ReportTypeIds
*/

export const reportTypeIds = /**@type {ReportTypeIds}*/ (_.mapValues(reportTypes, (v) => v.id));

export const getReportType = (/**@type {ReportTypeId | ReportTypeKey | undefined | null} */ idOrKey) =>
    _.isString(idOrKey)
        ? _.values(reportTypes).find((r) => r.key === idOrKey)
        : _.isFinite(idOrKey)
          ? _.values(reportTypes).find((r) => r.id === idOrKey)
          : undefined;
