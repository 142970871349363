import React from "react";
import clsx from "clsx";
import Checkmark from "../../assets/checkmark.svg";
import styles from "./Checkbox.module.scss";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string | JSX.Element;
    inputProps?: React.HTMLProps<HTMLInputElement>;
}

const onClick = (e) => {
    e.stopPropagation();
};

const Checkbox = ({ label, onChange, className, checked, disabled, inputProps, ...rest }: CheckboxProps) => (
    <label onClick={onClick} className={clsx(styles.container, !label && styles.container__withoutLabel, className)}>
        <input type="checkbox" {...rest} onChange={onChange} checked={checked} disabled={disabled} {...inputProps} />
        <div className={styles.checkmarkBox}>
            <Checkmark className={styles.checkmark} />
        </div>
        <div className={styles.checkboxLabel}>{label}</div>
    </label>
);

export default Checkbox;
