import React, { useCallback, useState } from "react";
import _ from "lodash";
import { FormControl, InputLabel, MenuItem, Select as SelectMui, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Flex from "@Shared/components/UI/Flex";
import { GenderId, genders, isGenderId } from "@Shared/helpers/genders";
import { getRegionById, isRegionId, RegionId, regions } from "@Shared/helpers/regions";
import { isRankId, Rank, RankId, ranks } from "@Shared/helpers/ranks";
import { isLevelId, Level, levels } from "@Shared/helpers/levels";
import { formatFiniteNumber, formatNotEmptyString } from "@Shared/helpers/formatters";
import { useEffect } from "react";
import { useMemo } from "react";
import { useThrottleMemo } from "@Shared/helpers/useThrottleMemo";
import { LevelId } from "@Shared/components/TournamentBracket";
import Select from "@Shared/components/UI/Select";

export type MemberFilterType = {
    genderId?: GenderId | null;
    rankId?: RankId | null;
    levelId?: LevelId | null;
    clubId?: number | null;
    regionId?: RegionId | null;
    member?: string | null;
    personalNumber?: string | null;
    isActiveMembership?: boolean | null;
    isDeleted?: boolean | null;
};

export type Verifier = (filter: MemberFilterType) => string | boolean;

export type MemberFilterProps = {
    filter?: MemberFilterType;
    onChange?: (filter: MemberFilterType) => void;
    onUndelayedChange?: (filter: MemberFilterType) => void;
    delay?: number;
    payloads?: {
        clubs?: { id: number; title: string }[];
    };
    hideNoClubOption?: boolean;
    disables?: boolean | Partial<Record<keyof MemberFilterType, boolean>>;
    required?: Partial<Record<keyof MemberFilterType, boolean>>;
    verifiers?: Partial<Record<keyof MemberFilterType, Verifier[] | Verifier>>;
    onErrorChange?: (error: { message?: string; hasError: boolean }) => void;
};

type MemberFilterLocalProps = {
    filter: MemberFilterType;
    onChange: (filter: MemberFilterType) => void;
    onUndelayedChange: (filter: MemberFilterType) => void;
    delay: number;
    payloads: {
        clubs: { id: number; title: string }[];
    };
    hideNoClubOption: boolean;
    disables: Record<keyof MemberFilterType, boolean>;
    required: Record<keyof MemberFilterType, boolean>;
    verifiers: Record<keyof MemberFilterType, Verifier>;
    onErrorChange: (error: { message?: string; hasError: boolean }) => void;
};

const filterFields: (keyof MemberFilterType)[] = [
    "genderId",
    "rankId",
    "levelId",
    "clubId",
    "regionId",
    "member",
    "personalNumber",
    "isActiveMembership",
    "isDeleted",
];

const spacing = 1;

const convertIdToBool = (value: any) => {
    switch (value) {
        case 0:
            return false;
        case 1:
            return true;
        default:
            return null;
    }
};
const convertBoolToId = (value: any) => {
    switch (value) {
        case false:
            return 0;
        case true:
            return 1;
        default:
            return "";
    }
};

const formatNotFalseWithGetter = (value: any, getDefaultValue: () => any) =>
    value !== false ? value : getDefaultValue();

const formatError = (error: ReturnType<Verifier> | undefined) => (_.isBoolean(error) ? error : _.isString(error));

const MemberFilterBase = (props: MemberFilterLocalProps) => {
    const { filter, onChange, payloads, hideNoClubOption, disables, verifiers, required } = props;

    let clubs = payloads.clubs;

    clubs = [...clubs];
    if (!hideNoClubOption) {
        clubs.unshift({ id: -1, title: "Нет спортивной организации" });
    }

    const getRankId = useCallback<(r: Rank) => string | number>((r) => r.id, []);
    const getRankName = useCallback<(r: Rank) => string>((r) => r.name, []);
    const onRankChangedHandle = useCallback<(r: Rank | null) => void>(
        (r) => onChange({ ...filter, rankId: !_.isNil(r) && isRankId(r.id) ? r.id : undefined }),
        [onChange, filter],
    );

    const getLevelId = useCallback<(l: Level) => string | number>((l) => l.id, []);
    const getLevelName = useCallback<(l: Level) => string>((l) => l.name, []);
    const onLevelChangedHandle = useCallback<(l: Level | null) => void>(
        (l) => onChange({ ...filter, levelId: !_.isNil(l) && isLevelId(l.id) ? l.id : undefined }),
        [onChange, filter],
    );

    // Render
    return (
        <Flex wrap="wrap" gap={spacing} sizeMax={12}>
            <Flex.Item size={9}>
                <TextField
                    fullWidth
                    label="Имя"
                    variant="outlined"
                    size="small"
                    disabled={disables.member}
                    required={required.member}
                    error={formatError(verifiers.member(filter))}
                    value={filter.member ?? ""}
                    onChange={(e) => onChange({ ...filter, member: formatNotEmptyString(e.target.value, null) })}
                />
            </Flex.Item>

            <Flex.Item size={3}>
                <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={disables.genderId}
                    required={required.genderId}
                >
                    <InputLabel>Пол</InputLabel>
                    <SelectMui
                        value={filter.genderId ?? ""}
                        onChange={(e) =>
                            onChange({ ...filter, genderId: isGenderId(e.target.value) ? e.target.value : null })
                        }
                        label="Пол"
                        disabled={disables.genderId}
                        required={required.genderId}
                        error={formatError(verifiers.genderId(filter))}
                    >
                        <MenuItem value="">
                            <em>Не выбрано</em>
                        </MenuItem>
                        {genders.map((gender) => (
                            <MenuItem key={gender.id} value={gender.id}>
                                {gender.name}
                            </MenuItem>
                        ))}
                    </SelectMui>
                </FormControl>
            </Flex.Item>

            <Flex.Item size={4}>
                <TextField
                    fullWidth
                    label="Членский номер"
                    size="small"
                    variant="outlined"
                    disabled={disables.personalNumber}
                    required={required.personalNumber}
                    error={formatError(verifiers.personalNumber(filter))}
                    value={filter.personalNumber ?? ""}
                    onChange={(e) =>
                        onChange({ ...filter, personalNumber: formatNotEmptyString(e.target.value, null) })
                    }
                />
            </Flex.Item>

            <Flex.Item size={4}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disables.regionId}
                    required={required.regionId}
                >
                    <Autocomplete
                        value={filter.regionId ?? null}
                        onChange={(_, value) => onChange({ ...filter, regionId: isRegionId(value) ? value : null })}
                        options={_.values(regions)
                            .filter((r) => r.countryId === 112)
                            .map((r) => r.id)}
                        getOptionLabel={(id) => getRegionById(id)?.fullName ?? `Неизвестный регион`}
                        noOptionsText="Нет совпадений"
                        disabled={disables.regionId}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Регион"
                                variant="outlined"
                                size="small"
                                disabled={disables.regionId}
                                required={required.regionId}
                                error={formatError(verifiers.regionId(filter))}
                            />
                        )}
                    />
                </FormControl>
            </Flex.Item>

            <Flex.Item size={4}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disables.clubId}
                    required={required.clubId}
                >
                    <Autocomplete
                        value={filter.clubId ?? null}
                        onChange={(_, value) => onChange({ ...filter, clubId: formatFiniteNumber(value, null) })}
                        options={clubs.map((club) => club.id)}
                        getOptionLabel={(id) =>
                            clubs.find((club) => club.id === id)?.title ?? `Неизвестная спортивная организация`
                        }
                        noOptionsText="Нет совпадений"
                        disabled={disables.clubId}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Спортивная организация"
                                variant="outlined"
                                size="small"
                                disabled={disables.clubId}
                                required={required.clubId}
                                error={formatError(verifiers.clubId(filter))}
                            />
                        )}
                    />
                </FormControl>
            </Flex.Item>

            <Flex.Item size={3}>
                <Select
                    value={filter.rankId ?? null}
                    onChange={onRankChangedHandle}
                    options={ranks}
                    getOptionKey={getRankId}
                    getOptionLabel={getRankName}
                    showEmptyOption
                    label="Техническая степень"
                    size="small"
                    fullWidth
                    disabled={disables.rankId}
                    required={required.rankId}
                    error={formatError(verifiers.rankId(filter))}
                />
            </Flex.Item>

            <Flex.Item size={3}>
                <Select
                    value={filter.levelId ?? null}
                    onChange={onLevelChangedHandle}
                    options={levels}
                    getOptionKey={getLevelId}
                    getOptionLabel={getLevelName}
                    showEmptyOption
                    emptyOptionLabel="Не выбрано"
                    label="Спортивный разряд, звание"
                    size="small"
                    fullWidth
                    disabled={disables.levelId}
                    required={required.levelId}
                    error={formatError(verifiers.levelId(filter))}
                />
            </Flex.Item>

            <Flex.Item size={3}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disables.isDeleted}
                    required={required.isDeleted}
                >
                    <InputLabel>Статус Профиля</InputLabel>
                    <SelectMui
                        value={convertBoolToId(filter.isDeleted)}
                        onChange={(e) => onChange({ ...filter, isDeleted: convertIdToBool(e.target.value) })}
                        label="Статус Профиля"
                        disabled={disables.isDeleted}
                        required={required.isDeleted}
                        error={formatError(verifiers.isDeleted(filter))}
                    >
                        <MenuItem value="">
                            <em>Не выбран</em>
                        </MenuItem>
                        <MenuItem value={0}>Активен</MenuItem>
                        <MenuItem value={1}>Удалён</MenuItem>
                    </SelectMui>
                </FormControl>
            </Flex.Item>

            <Flex.Item size={3}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disables.isActiveMembership}
                    required={required.isActiveMembership}
                >
                    <InputLabel>Членство</InputLabel>
                    <SelectMui
                        value={convertBoolToId(filter.isActiveMembership)}
                        onChange={(e) => onChange({ ...filter, isActiveMembership: convertIdToBool(e.target.value) })}
                        label="Членство"
                        disabled={disables.isActiveMembership}
                        required={required.isActiveMembership}
                        error={formatError(verifiers.isActiveMembership(filter))}
                    >
                        <MenuItem value="">
                            <em>Не выбрано</em>
                        </MenuItem>
                        <MenuItem value={1}>Активно</MenuItem>
                        <MenuItem value={0}>Неактивно</MenuItem>
                    </SelectMui>
                </FormControl>
            </Flex.Item>
        </Flex>
    );
};

const MemberFilterWithErrors = (props: MemberFilterLocalProps) => {
    const { filter, verifiers, onErrorChange } = props;

    const error = useMemo(
        () =>
            _.values(verifiers)
                .map((v) => v(filter))
                .find((r) => r !== false),
        [filter, verifiers],
    );

    useEffect(() => {
        onErrorChange({
            message: _.isString(error) ? error : undefined,
            hasError: formatError(error),
        });
    }, [error]);

    return MemberFilterBase(props);
};

const MemberFilterWithDelay = (props: MemberFilterLocalProps) => {
    const {
        delay,
        filter: filterFromProps,
        onChange, //TODO rename into onDelayedChange
        onUndelayedChange, //TODO rename into onChange
    } = props;

    const [undelayedFilter, setUndelayedFilter] = useState<MemberFilterType>(filterFromProps);
    const [filter] = useThrottleMemo(() => undelayedFilter, undelayedFilter, delay, [undelayedFilter]);

    useEffect(() => onUndelayedChange(undelayedFilter), [undelayedFilter]);

    useEffect(() => onChange(filter), [filter]);

    useEffect(() => setUndelayedFilter(filterFromProps), [filterFromProps]);

    return MemberFilterWithErrors({ ...props, filter: undelayedFilter, onChange: setUndelayedFilter });
};

export const convertToLocalDisables = (disables: MemberFilterProps["disables"]): MemberFilterLocalProps["disables"] => {
    if (_.isBoolean(disables)) {
        return filterFields.reduce((r, f) => ({ ...r, [f]: disables }), {}) as MemberFilterLocalProps["disables"];
    }

    if (_.isObject(disables)) {
        return filterFields.reduce(
            (r, f) => ({ ...r, [f]: disables[f] ?? false }),
            {},
        ) as MemberFilterLocalProps["disables"];
    }

    return filterFields.reduce((r, f) => ({ ...r, [f]: false }), {}) as MemberFilterLocalProps["disables"];
};

export const convertToLocalRequired = (required: MemberFilterProps["required"]): MemberFilterLocalProps["required"] => {
    if (_.isObject(required)) {
        return filterFields.reduce(
            (r, f) => ({ ...r, [f]: required[f] ?? false }),
            {},
        ) as MemberFilterLocalProps["required"];
    }

    return filterFields.reduce((r, f) => ({ ...r, [f]: false }), {}) as MemberFilterLocalProps["required"];
};

export const convertToLocalVerifiers = (
    verifiers: MemberFilterProps["verifiers"],
    required: MemberFilterLocalProps["required"],
): MemberFilterLocalProps["verifiers"] => {
    let verifiersNotNull = verifiers ?? {};

    let resultWithVerifiersArray = filterFields.reduce(
        (r, f) =>
            _.assign(r, {
                [f]: _.isArray(verifiersNotNull[f])
                    ? verifiersNotNull[f]
                    : _.isFunction(verifiersNotNull[f])
                      ? [verifiersNotNull[f]]
                      : [() => false],
            }),
        {},
    ) as Record<keyof MemberFilterType, Verifier[]>;

    resultWithVerifiersArray = _.mapValues(resultWithVerifiersArray, (verifiers, key) =>
        required[key] === true
            ? [((f) => !_.isFinite(f[key]) && _.isEmpty(f[key])) as Verifier, ...verifiers]
            : verifiers,
    );

    const result = _.mapValues(resultWithVerifiersArray, (verifier) =>
        verifier.reverse().reduce(
            (r, c) => (f) => formatNotFalseWithGetter(c(f), () => r(f)),
            () => false,
        ),
    ) as MemberFilterLocalProps["verifiers"];

    return result;
};

const MemberFilterWithDefaults = (props: MemberFilterProps) => {
    const {
        disables: disablesFromProps,
        required: requiredFromProps,
        verifiers: verifiersFromProps,
        filter: filterFromProps,
        onChange: onChangeFromProps,
        onUndelayedChange: onUndelayedChangeFromProps,
        onErrorChange: onErrorChangeFromProps,
        payloads: payloadsFromProps,
    } = props;

    const disablesLocal = useMemo(() => convertToLocalDisables(disablesFromProps), [disablesFromProps]);

    const requiredLocal = useMemo(() => convertToLocalRequired(requiredFromProps), [requiredFromProps]);

    const verifiersLocal = useMemo(
        () => convertToLocalVerifiers(verifiersFromProps, requiredLocal),
        [verifiersFromProps, requiredLocal],
    );

    const filterLocal = useMemo(() => filterFromProps ?? {}, [filterFromProps]);

    const onChangeLocal = useMemo(() => onChangeFromProps ?? (() => {}), [onChangeFromProps]);

    const onUndelayedChangeLocal = useMemo(
        () => onUndelayedChangeFromProps ?? (() => {}),
        [onUndelayedChangeFromProps],
    );

    const onErrorChangeLocal = useMemo(() => onErrorChangeFromProps ?? (() => {}), [onErrorChangeFromProps]);

    const payloadsLocal = useMemo<MemberFilterLocalProps["payloads"]>(
        () => ({
            clubs: payloadsFromProps?.clubs ?? [],
        }),
        [payloadsFromProps],
    );

    const localProps: MemberFilterLocalProps = {
        ...props,
        delay: props.delay ?? 0,
        filter: filterLocal,
        onChange: onChangeLocal,
        onUndelayedChange: onUndelayedChangeLocal,
        payloads: payloadsLocal,
        hideNoClubOption: props.hideNoClubOption ?? false,
        disables: disablesLocal,
        required: requiredLocal,
        verifiers: verifiersLocal,
        onErrorChange: onErrorChangeLocal,
    };

    return MemberFilterWithDelay(localProps);
};

const MemberFilter = MemberFilterWithDefaults;
export default MemberFilter;
