import { getQueryUrl } from "@Shared/helpers/api";
import { PermissionId } from "@Shared/helpers/permissionsHelper";
import { apiSlice } from "@Shared/app/api/apiSlice";
import { WrappedResponse } from "../common/models";

export interface AccountPermission {
    permissionId: PermissionId;
}

export const accountsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ["CheckAccount"] }).injectEndpoints({
    endpoints: (builder) => ({
        accountPermissionsGet: builder.query<WrappedResponse<AccountPermission[]>, void>({
            query: () => getQueryUrl(["v1", "account", "permissions"]),
            providesTags: ["Permissions"],
            keepUnusedDataFor: 3,
        }),
        checkAccountExists: builder.query<boolean | undefined, string>({
            query: (email) => getQueryUrl(["v1", "account", "check-account-exists", email]),
            providesTags: ["CheckAccount"],
            keepUnusedDataFor: 3,
            transformResponse: (rv: WrappedResponse<boolean>) => rv.result,
        }),
        createAccount: builder.mutation<true | undefined, { memberId: number }>({
            query: (body) => {
                return {
                    url: getQueryUrl(["v1", "account"]),
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["Accounts", "Members"],
            transformResponse: (rv: WrappedResponse<true>) => rv.result,
        }),
        deleteAccount: builder.mutation<true | undefined, number>({
            query: (accountId) => {
                return {
                    url: getQueryUrl(["v1", "account", accountId]),
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Accounts", "Members"],
            transformResponse: (rv: WrappedResponse<true>) => rv.result,
        }),
        restoreAccount: builder.mutation<true | undefined, number>({
            query: (accountId) => {
                return {
                    url: getQueryUrl(["v1", "account", "restore", accountId]),
                    method: "PUT",
                };
            },
            invalidatesTags: ["Accounts", "Members"],
            transformResponse: (rv: WrappedResponse<true>) => rv.result,
        }),
    }),
});

export const {
    useAccountPermissionsGetQuery,
    useLazyAccountPermissionsGetQuery,
    useLazyCheckAccountExistsQuery,
    useCreateAccountMutation,
    useDeleteAccountMutation,
    useRestoreAccountMutation,
} = accountsApiSlice;
