import React from "react";
import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { sharedSelectors } from "../helpers/selectors";

import Loader from "../../Shared/components/Loader";

const style = {
    position: `absolute`,
    height: `100%`,
    width: `100%`,
    background: `rgba(0, 0, 0, 0.1)`,
    outline: "none",
};

const LoaderHOC = () => {
    const isLoading = useSelector((state) => sharedSelectors.isLoading(state));
    const isLoadingHided = useSelector((state) => sharedSelectors.isLoadingHided(state));

    return isLoadingHided ? null : (
        <Modal open={isLoading || false} hideBackdrop={true}>
            <Box sx={style}>
                <Loader />
            </Box>
        </Modal>
    );
};

export default LoaderHOC;
