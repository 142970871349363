import _ from "lodash";
import { types as sharedActionsTypes } from "../redux/actions/SharedActions";
import i18n from "@Admin/i18n";

let lastActionTimes = {};

const getErrorMessageByCode = (code, data) => {
    if (!code) return undefined;
    if (!i18n.exists(`errors.${code}`, data)) return undefined;
    return i18n.t(`errors.${code}`, data);
};

function apiMiddleware({ getState, dispatch }) {
    return (next) => async (action) => {
        if (!isRSAA(action)) {
            return next(action);
        }
        const {
            types,
            callAPI,
            isLastRequestCheck = false,
            isServerResponse = false,
            hideDefaultErrorMessage = false,
            payload,
            onResponse,
        } = action;

        const [successType, failureType, waitingType] = normalizeTypeDescriptors(types);
        try {
            if (waitingType) {
                dispatch({ type: sharedActionsTypes.SHOW_LOADER });
            }

            const currentActionTime = Date.now();
            lastActionTimes = {
                ...lastActionTimes,
                [successType.type]: currentActionTime,
            };

            const response = await callAPI();

            if (isServerResponse) {
                if (response.status !== 200) {
                    const apiResult = await response.json();
                    const { reasonPhrase, errorCode, errorData } = apiResult;

                    if (response.status === 401) {
                        dispatch({ type: "UNAUTHORIZED", payload: response });
                        return;
                    }

                    if (hideDefaultErrorMessage) {
                        return;
                    }

                    const errorMessage = getErrorMessageByCode(errorCode, errorData) ?? reasonPhrase;
                    next({
                        type: `PROMPT_SHOW`,
                        payload: {
                            message: errorMessage,
                            trace: result,
                            type: `error`,
                        },
                    });

                    return;
                }

                const resultPayload = _.isFunction(payload) ? await payload(getState(), response) : response;

                if (isLastRequestCheck && lastActionTimes[successType.type] !== currentActionTime) return;

                next(actionWith(successType, { payload: resultPayload }));
                return;
            }

            const apiResult = await response.json();

            if (_.isFunction(onResponse)) {
                await onResponse(apiResult);
            }

            const { statusCode, reasonPhrase, result, errorCode, errorData } = apiResult;

            if (response.status === 200) {
                const mappedApiResult = {
                    statusCode: statusCode,
                    error: reasonPhrase,
                    result,
                };
                const resultPayload = _.isFunction(payload)
                    ? await payload(getState(), mappedApiResult)
                    : mappedApiResult;

                if (isLastRequestCheck && lastActionTimes[successType.type] !== currentActionTime) return;

                if (reasonPhrase && !result) {
                    next(actionWith(failureType, { payload: { error: reasonPhrase, type: `error` } }));
                } else {
                    next(actionWith(successType, { payload: resultPayload }));
                }
                return;
            }

            if (response.status === 401) {
                dispatch({ type: "UNAUTHORIZED", payload: response });
                next({
                    type: `AUTH_ERROR`,
                    payload: `Неправильный логин или пароль`,
                });
                return;
            }

            if (response.status === 403) {
                next({
                    type: `PROMPT_SHOW`,
                    payload: {
                        message: `У вас недостаточно прав. Обратитесь к администратору.`,
                        trace: result,
                        type: `error`,
                    },
                });
                return;
            }

            if (hideDefaultErrorMessage) {
                return;
            }

            next({
                type: `PROMPT_SHOW`,
                payload: {
                    message: getErrorMessageByCode(errorCode, errorData) ?? reasonPhrase,
                    trace: result,
                    type: `error`,
                },
            });
        } catch (exc) {
            console.log(exc);
            return next(actionWith(failureType, { payload: { message: exc.message, type: `error` } }));
        } finally {
            dispatch({ type: sharedActionsTypes.HIDE_LOADER });
        }
    };
}

function isRSAA(action) {
    return _.isPlainObject(action) && _.keys(action).every(isValidAction);
}
function isValidAction(action) {
    return (
        [
            "types",
            "payload",
            "callAPI",
            "isLastRequestCheck",
            "isServerResponse",
            "hideDefaultErrorMessage",
            "onResponse",
        ].indexOf(action) > -1
    );
}
function normalizeTypeDescriptors(types) {
    let [successType, failureType, waitingType] = types;

    if (_.isString(successType) || _.isSymbol(successType)) {
        successType = { type: successType };
    }

    if (_.isString(waitingType) || _.isSymbol(waitingType)) {
        waitingType = { type: waitingType };
    }

    if (_.isString(failureType) || _.isSymbol(failureType)) {
        failureType = { type: failureType };
    } else {
        failureType = { type: `PROMPT_SHOW` };
    }

    return [successType, failureType, waitingType];
}

function actionWith(...data) {
    const finalAction = _.assign(...data);
    return finalAction;
}
export default apiMiddleware;
