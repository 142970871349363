import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";

export const apiTypes = {
    loadSettingsType: createApiTypes("SETTINGS", "LOAD", false),
};

const types = {
    RESET_SETTINGS: "RESET_SETTINGS",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const settingsReducer = createReducer(
    {},
    {
        ...apiHandlers,
        [types.RESET_SETTINGS]: (state) => ({ ...state, settings: null }),
    },
);

const getSettings = () => (dispatch) => {
    dispatch({
        types: apiTypes.loadSettingsType,
        callAPI: () => proxy.settingsGet(),
        payload: (state, { result }) => {
            const gender = [
                { id: 1, name: "Мужчина" },
                { id: 2, name: "Женщина" },
            ];
            const resultLoc = { ...result, gender };
            return {
                settings: resultLoc,
            };
        },
    });
};

const resetSettings = () => (dispatch) => {
    dispatch({
        type: types.RESET_SETTINGS,
    });
};

export const settingsActions = {
    resetSettings,
    getSettings,
};
