import { proxy } from "~/Admin/core/api";
import _ from "lodash";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { formatArray } from "../../../Shared/helpers/formatters";
import { tournamentCategorySelectors } from "../../helpers/selectors";
import { parseApiModels } from "../../../Shared/helpers/tournamentCategory";

export const apiTypes = {
    loadTournamentCategoryListType: createApiTypes("TOURNAMENT_CATEGORY", "LOAD", false),
};

export const types = {
    RESET_TOURNAMENT_CATEGORY_CACHE: "RESET_TOURNAMENT_CATEGORY_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentCategoryReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_CATEGORY_CACHE]: (state) => ({ ...state, cache: [] }),
    },
);

const getTournamentCategoryList = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentCategoryListType,
        callAPI: () => proxy.tournamentCategoryGet(filter),
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentCategorySelectors.getTournamentCategoryCache(state)).filter(
                    (pair) => !_.isEqual(pair.filter, filter),
                ),
                {
                    filter,
                    items: parseApiModels(result),
                },
            ],
        }),
    });
};

const resetTournamentCategoryCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_CATEGORY_CACHE,
    });
};

export const tournamentCategoryActions = {
    getTournamentCategoryList,
    resetTournamentCategoryCache,
};
