import _ from "lodash";
import { Dispatch } from "redux";
import {
    TournamentNodeMemberGetRequest,
    TournamentNodeMemberGetResponse,
} from "../../../Shared/helpers/api.tournamentNodeMembers";
import { proxy } from "../../core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "../../core/utils";
import { tournamentNodeMemberSelectors } from "../../helpers/selectors";

export const apiTypes = {
    loadTournamentNodeMemberListType: createApiTypes("TOURNAMENT_NODE_MEMBER", "LOAD", false),
};

export const types = {
    RESET_TOURNAMENT_NODE_MEMBER_CACHE: "RESET_TOURNAMENT_NODE_MEMBER_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentNodeMemberReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_NODE_MEMBER_CACHE]: (state: any) => ({ ...state, cache: [] }),
    },
);

const getTournamentNodeMemberList = (request: TournamentNodeMemberGetRequest) => (dispatch: Dispatch<any>) => {
    return dispatch({
        types: apiTypes.loadTournamentNodeMemberListType,
        callAPI: () => proxy.tournamentNodeMemberGet(request),
        payload: (state: any, { result }: { result: TournamentNodeMemberGetResponse }) => ({
            cache: [
                ...tournamentNodeMemberSelectors
                    .getTournamentNodeMemberCache(state)
                    .filter((item) => !_.isEqual(item.request, request)),
                {
                    request,
                    response: result,
                },
            ],
        }),
    });
};

const resetTournamentNodeMemberCache = () => (dispatch: Dispatch<any>) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_NODE_MEMBER_CACHE,
    });
};

export const tournamentNodeMemberActions = {
    getTournamentNodeMemberList,
    resetTournamentNodeMemberCache,
};
