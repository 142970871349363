import { useEffect, useMemo } from "react";
import { useThrottleState } from "./useThrottleState";

export type UseThrottleMemoResult<TValue> = [value: TValue, isPending: boolean];

export const useThrottleMemo = <TValue>(
    factory: () => TValue,
    initialValue: TValue,
    ms: number,
    deps: any[],
): UseThrottleMemoResult<TValue> => {
    const { value, setThrottledValue, isPending } = useThrottleState<TValue>(initialValue, ms);

    useEffect(() => setThrottledValue(factory), deps);

    const result = useMemo<UseThrottleMemoResult<TValue>>(() => [value, isPending], [value, isPending]);

    return result;
};
