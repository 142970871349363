import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ruLocaleShared from "@Shared/translations/ru-RU.json";
import ruLocaleAdmin from "@Admin/translations/ru-RU.json";
//TODO: install language detector import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(initReactI18next).init({
    lng: "ru",
    debug: true,
    fallbackLng: "ru",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        ru: {
            translation: {
                ...ruLocaleShared,
                ...ruLocaleAdmin,
            },
        },
    },
});

export default i18n;
