import _ from "lodash";
import { v4 as uuid } from "uuid";
import { getMemberFullName } from "./memberNameHelper";

export const tournamentMemberCoreFields = [
    "id",
    "memberId",
    "foreignMemberId",
    "firstName",
    "lastName",
    "patronymName",
    "genderId",

    "tournamentId",
    "tournamentCategoryId",
];

export const tournamentMemberDataFields = [
    "clubId",
    "clubName",
    "clubShortName",
    "clubCity",
    "clubCountryId",
    "clubRegionLocalId",
    "levelId",
    "rankId",
    "trainers",
];

export const tournamentMemberFields = [...tournamentMemberCoreFields, ...tournamentMemberDataFields];

export const filterMembers = (members, filter) => {
    if (!_.isArray(members)) return members;

    const { fullName } = filter;

    let membersFiltered = members;
    if (!_.isEmpty(fullName)) {
        const fullNameInLowerCase = fullName.toLowerCase();
        membersFiltered = membersFiltered.filter((m) =>
            getMemberFullName(m).toLowerCase().startsWith(fullNameInLowerCase),
        );
    }

    return membersFiltered;
};

export const parseApiModel = (apiModel) => {
    return {
        ..._.pick(apiModel.data, tournamentMemberDataFields),
        ..._.pick(apiModel, tournamentMemberCoreFields),
    };
};

export const convertToApiModel = (model) => {
    model = {
        data: _.pick(model, tournamentMemberDataFields),
        ..._.pick(model, tournamentMemberCoreFields),
    };

    if (!_.isFinite(model.id)) {
        model = _.omit(model, ["id"]);
    }

    return model;
};

export const parseApiModels = (apiModels) => {
    return _.isArray(apiModels) ? apiModels.map((apiModel) => parseApiModel(apiModel)) : apiModels;
};

export const convertToApiModels = (models) => {
    return _.isArray(models) ? models.map((model) => convertToApiModel(model)) : models;
};

export const formatMemberForTournamentToTournamentMember = (member, tournamentId, tournamentCategoryId) => {
    const apiModel = {
        id: uuid(),
        memberId: member.memberId,
        foreignMemberId: member.foreignMemberId,
        firstName: member.firstName,
        lastName: member.lastName,
        patronymName: member.patronymName,
        genderId: member.gender,

        tournamentId,
        tournamentCategoryId,

        clubId: member.clubId ?? member.foreignClubId,
        clubName: member.clubName,
        clubShortName: member.clubShortName,
        clubCity: member.clubCity,
        clubCountryId: member.clubCountryId,
        clubRegionLocalId: member.clubRegionLocalId,

        levelId: member.levelId,
        rankId: member.rankId,
        trainers: member.trainers,
    };

    return apiModel;
};
