import { convertToApiModels as convertCategoriesToApiModels } from "./tournamentCategory";

export const tournamentGroupCoreFields = ["id", "tournamentDivisionId", "name"];

export const tournamentGroupDataFields = ["yearFrom", "yearTo"];

export const tournamentGroupFields = [...tournamentGroupCoreFields, ...tournamentGroupDataFields];

export const parseApiModel = (apiModel) => {
    return {
        ..._.pick(apiModel.data, tournamentGroupDataFields),
        ..._.pick(apiModel, tournamentGroupCoreFields),
    };
};

export const convertToApiModel = (model) => {
    return {
        data: _.pick(model, tournamentGroupDataFields),
        categories: convertCategoriesToApiModels(model.categories),
        ..._.pick(model, tournamentGroupCoreFields),
    };
};

export const parseApiModels = (apiModels) => {
    return _.isArray(apiModels) ? apiModels.map((apiModel) => parseApiModel(apiModel)) : apiModels;
};

export const convertToApiModels = (models) => {
    return _.isArray(models) ? models.map((model) => convertToApiModel(model)) : models;
};

export const groupFormatter = (group) => {
    if (_.isNil(group)) return {};

    let selectedGroupFormatted = {
        name: group.name,
        yearTo: group.yearTo,
        yearFrom: group.yearFrom,
        male: [],
        female: [],
        mixed: [],
    };

    if (_.isArray(group.categories)) {
        group.categories.map((category) => {
            if (category.genderId == 1) {
                selectedGroupFormatted.male.push(category.weightMax ?? category.weightMin + "+");
            } else if (category.genderId == 2) {
                selectedGroupFormatted.female.push(category.weightMax ?? category.weightMin + "+");
            } else {
                selectedGroupFormatted.mixed.push(category.weightMax ?? category.weightMin + "+");
            }

            selectedGroupFormatted.male.sort((a, b) => {
                return a.weight - b.weight;
            });
            selectedGroupFormatted.female.sort((a, b) => {
                return a.weight - b.weight;
            });
            selectedGroupFormatted.mixed.sort((a, b) => {
                return a.weight - b.weight;
            });
        });
    }
    return selectedGroupFormatted;
};

export const createSelectedGroupProps = (group) => {
    if (!group) return {};
    return {
        selectedGroupName: group?.name,
        selectedGroupYearFrom: group?.yearFrom,
        selectedGroupYearTo: group?.yearTo,
        selectedGroupMaleCategoriesString: group?.male,
        selectedGroupFemaleCategoriesString: group?.female,
        selectedGroupMixedCategoriesString: group?.mixed,
    };
};
