import React from "react";
import styles from "../LoginContainer.module.scss";
import Input from "@Admin/components/Input/Input";
import Button, { ButtonVariant } from "@Shared/components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { getTextFieldValidation, passwordRegExp, verifyPassword } from "@Shared/helpers/validation";
import { isNil } from "lodash";

interface ChangePasswordFormFields {
    newPassword: string | null;
    newPasswordRepeat: string | null;
}

interface ChangePasswordFormProps {
    isLoading: boolean;
    authMessage?: string;
    authError?: string | null;
    confirmAction?: (loginData: any, passwordData: any) => (dispatch: any) => void;
    resetAuthError?: () => (dispatch: any) => void;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const { isLoading, confirmAction, resetAuthError, authError, authMessage } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const recoveryCode = params.recoveryCode;

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangePasswordFormFields>({
        mode: "all",
        defaultValues: {
            newPassword: null,
            newPasswordRepeat: null,
        },
    });

    const onChangePasswordSubmit: SubmitHandler<ChangePasswordFormFields> = (data: ChangePasswordFormFields) => {
        resetAuthError?.();
        navigate("/login");
        confirmAction?.({ recoveryCode }, { password: data.newPassword, repeatPassword: data.newPasswordRepeat });
    };

    return (
        <>
            <div className={styles.formHeader}>
                <h2 className={styles.title}>{t("auth.changePassword.passwordChange")}</h2>
            </div>
            <div className={styles.inputs}>
                <Input
                    placeholder={t("auth.changePassword.newPassword")}
                    type="password"
                    name={"newPassword"}
                    inputProps={{
                        ...register("newPassword", {
                            ...getTextFieldValidation(true, passwordRegExp, 5, 50),
                        }),
                    }}
                    error={!isNil(errors.newPassword)}
                    errorText={errors.newPassword?.message}
                />
                <Input
                    placeholder={t("auth.changePassword.newPasswordRepeat")}
                    type="password"
                    name={"newPasswordRepeat"}
                    inputProps={{
                        ...register("newPasswordRepeat", {
                            ...getTextFieldValidation(true, passwordRegExp, 5, 50),
                            validate: () => verifyPassword(getValues("newPassword"), getValues("newPasswordRepeat")),
                        }),
                    }}
                    error={!isNil(errors.newPasswordRepeat)}
                    errorText={errors.newPasswordRepeat?.message}
                />
            </div>
            {(!isNil(authError) || !isNil(authMessage)) && (
                <p className={!isNil(authError) ? styles.authError : styles.authMessage}>{authError || authMessage}</p>
            )}
            <div className={styles.footerBtn}>
                <Button
                    buttonVariant={ButtonVariant.Blue}
                    type="submit"
                    disabled={isLoading}
                    title={t("auth.changePassword.confirm")}
                    onClick={handleSubmit(onChangePasswordSubmit)}
                />
            </div>
        </>
    );
};

export default ChangePasswordForm;
