//@ts-check

import _ from "lodash";
import ReportPayloadCommonBuilder from "../../../components/ReportPayloadBuilders/ReportPayloadCommonBuilder";
import MemberFilterContainer from "../../MemberFilterContainer";

/**
    @typedef {import("../../../helpers/reportTypes").ReportPayloadCommonBuilderProps} ReportPayloadCommonBuilderProps
 */

const ReportPayloadCommonBuilderBaseContainer = ReportPayloadCommonBuilder;

const ReportPayloadCommonBuilderWithDefaultsContainer = (/**@type {ReportPayloadCommonBuilderProps} */ props) => {
    const componentProps = _.defaults(
        {},
        props,
        /**@type {ReportPayloadCommonBuilderProps} */ ({
            memberFilterRender: MemberFilterContainer,
        }),
    );

    return ReportPayloadCommonBuilderBaseContainer(componentProps);
};

const ReportPayloadCommonBuilderContainer = ReportPayloadCommonBuilderWithDefaultsContainer;
export default ReportPayloadCommonBuilderContainer;
