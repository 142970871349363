import _ from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

export type UseThrottleStateResult<TValue> = {
    value: TValue;
    setValue: Dispatch<SetStateAction<TValue>>;
    setThrottledValue: Dispatch<SetStateAction<TValue>>;
    isPending: boolean;
};

export const useThrottleState = <TValue>(
    initialValue: TValue | (() => TValue),
    ms: number,
): UseThrottleStateResult<TValue> => {
    const [value, setValue] = useState<TValue>(initialValue);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

    const isMountedRef = useRef(false);
    useEffect(
        () => (
            (isMountedRef.current = true),
            () => {
                isMountedRef.current = false;
            }
        ),
        [],
    );

    const setValueWithTimeoutReset = useCallback<Dispatch<SetStateAction<TValue>>>((value: SetStateAction<TValue>) => {
        setTimeoutId((prevTimeoutId) => {
            clearTimeout(prevTimeoutId);
            return undefined;
        });

        setValue(value);
    }, []);

    const setThrottledValue = useCallback<Dispatch<SetStateAction<TValue>>>((value: SetStateAction<TValue>) => {
        setTimeoutId((prevTimeoutId) => {
            clearTimeout(prevTimeoutId);

            const newTimeoutId = setTimeout(() => {
                if (isMountedRef.current) {
                    setValue(value);
                    setTimeoutId((prevInnerTimeoutId) =>
                        prevInnerTimeoutId === newTimeoutId ? undefined : prevInnerTimeoutId,
                    );
                }
            }, ms);

            return newTimeoutId;
        });
    }, []);

    const result = useMemo<UseThrottleStateResult<TValue>>(
        () => ({
            value,
            setValue: setValueWithTimeoutReset,
            setThrottledValue,
            isPending: !_.isUndefined(timeoutId),
        }),
        [value, setThrottledValue, setValueWithTimeoutReset, timeoutId],
    );

    return result;
};
