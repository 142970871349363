import React from "react";
import availableFlags from "./flags.json";
import PropTypes from "prop-types";

const FlagBase = (props) => {
    const {
        name = null,
        country: countryFromProps = "_unknown",
        shiny = false,
        format = "png",
        pngSize = 32,
        alt = null,
        title = null,
        basePath = "/img/flags",
        className = "",
        width = null,
        height = null,
        draggable = true,
    } = props;

    let country = name ? name : countryFromProps;

    const type = shiny ? "shiny" : "flat";

    const folder = format === "icns" || format === "ico" || format === "svg" ? format : pngSize;

    const altText = alt ? alt : country;

    const file = country.charAt(0) === "_" ? country : country.toUpperCase();

    const flag = ~availableFlags.flags.indexOf(file) ? file : "_unknown";

    return (
        <img
            alt={altText}
            title={title}
            src={basePath + "/flags-iso/" + type + "/" + folder + "/" + flag + "." + format}
            className={className}
            width={width}
            height={height}
            draggable={draggable}
        />
    );
};

const Flag = (props) => <FlagBase format="png" pngSize={16} basePath="/image/flags" {...props} />;

Flag.propTypes = {
    // Alternative text of the flag <img> HTML tag.
    alt: PropTypes.string,

    // Country or region for this flag. (Legacy)
    country: PropTypes.string,

    // Height of the flag <img> HTML tag.
    height: PropTypes.number,

    // Image className
    className: PropTypes.string,

    // Name of country or region for this flag. (Legacy)
    name: PropTypes.string,

    // Shiny or Flat
    shiny: PropTypes.bool,

    // Title attribute for the flag <img> HTML tag
    title: PropTypes.string,

    // Width of the flag <img> HTML tag.
    width: PropTypes.number,
};

export default Flag;
