import { apiSlice } from "@Shared/app/api/apiSlice";
import { WrappedResponse } from "@Shared/features/common/models";
import { getQueryUrl } from "@Shared/helpers/api";
import _ from "lodash";
import { useEffect, useState } from "react";

export const authApiSliceV2 = apiSlice.enhanceEndpoints({ addTagTypes: ["Check"] }).injectEndpoints({
    endpoints: (builder) => ({
        logoutV2: builder.mutation<true | undefined, void>({
            query: () => ({
                url: "/v2/auth/logout",
                method: "POST",
            }),
            transformResponse: (rv: WrappedResponse<true>) => rv.result,
            invalidatesTags: ["Permissions", "Profile", "Check"],
        }),
        checkV2: builder.query<boolean | undefined, void>({
            query: () => ({
                url: "/v2/auth/check",
                method: "GET",
            }),
            transformResponse: (rv: WrappedResponse<boolean>) => rv.result,
            providesTags: ["Check"],
        }),
    }),
});

export const { useLogoutV2Mutation, useCheckV2Query, useLazyCheckV2Query } = authApiSliceV2;
