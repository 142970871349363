import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { location, sharedActions } from "./SharedActions";
import i18n from "@Admin/i18n";
import { isNil } from "lodash";

//Types
export const apiTypes = {
    loadProfileType: createApiTypes("USER_PROFILE", "GET", false),
    saveProfileType: createApiTypes("USER_PROFILE", "SAVE"),
    changeProfileType: createApiTypes("USER_PROFILE", "CHANGE"),
};

export const types = {
    PROFILE_MERGE: `PROFILE_MERGE`,
};
//Reducers
const apiHandlers = createCombinedApiHandlers(apiTypes);

export const profileReducer = createReducer(
    {},
    {
        ...apiHandlers,
        [types.PROFILE_MERGE]: ({ profile, ...state }, action) => {
            return {
                ...state,
                profile: {
                    ...profile,
                    ...action.payload,
                },
            };
        },
    },
);

const getProfile = () => {
    return {
        types: apiTypes.loadProfileType,
        callAPI: () => proxy.profileGet(),
        payload: (state, { result }) => {
            return {
                profile: result,
            };
        },
    };
};

const updateProfile = (profileData) => (dispatch) => {
    dispatch({
        type: types.PROFILE_MERGE,
        payload: profileData,
    });
};

const setProfile = (profile) => (dispatch) => {
    dispatch({
        types: apiTypes.saveProfileType,
        callAPI: () => proxy.memberDataSet(profile),
        payload: () => {
            dispatch(getProfile());
            dispatch(location.push(`/account`));
        },
    });
};

const changeProfile = (passwords) => (dispatch) => {
    dispatch({
        types: apiTypes.changeProfileType,
        callAPI: () => proxy.profileChangePost(passwords),
        payload: (state, { result }) => {
            if (isNil(result)) {
                dispatch(
                    sharedActions.setPromptMessage(
                        i18n.t("profile.accountSettings.passwordChange.passwordChangeSucceed"),
                        `success`,
                    ),
                );
            } else {
                dispatch(sharedActions.setPromptMessage(i18n.t(`errors.${result.code}`), `error`));
            }
        },
    });
};

export const profileActions = {
    getProfile,
    setProfile,
    updateProfile,
    changeProfile,
};
