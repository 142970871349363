import { proxy } from "~/Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";

export const apiTypes = {
    generateReport: createApiTypes("REPORTS", "LOAD", false),
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const reportReducer = createReducer(
    {},
    {
        ...apiHandlers,
    },
);

const generateReport = (data) => (dispatch) =>
    dispatch({
        types: apiTypes.generateReport,
        callAPI: () => proxy.reportPost(data),
        isServerResponse: true,
        payload: async (state, response) => {
            if (response.status === 200) {
                const disposition = response.headers.get("Content-Disposition");
                const filename = disposition.match(/filename=['"]?([^;\n'"]*)['"]?/)[1];

                const blob = await response.blob();

                let link = document.createElement("a");
                link.download = filename;
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },
    });

export const reportActions = {
    generateReport,
};
