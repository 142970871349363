import { isEqualByRootPrototypesOrId } from "./object";
import { convertToApiModels as convertGroupsToApiModels } from "./tournamentGroup";

export const tournamentDivisionFields = ["id", "tournamentId", "name", "templateName", "accountId"];

export const isEqual = (divisions) => {
    return isEqualByRootPrototypesOrId(divisions);
};

export const convertToApiModel = (model) => {
    return {
        groups: convertGroupsToApiModels(model.groups),
        ..._.pick(model, tournamentDivisionFields),
    };
};
export const convertToApiModels = (models) => {
    return _.isArray(models) ? models.map((model) => convertToApiModel(model)) : models;
};
