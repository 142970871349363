import { proxy, removeAuthorization } from "@Admin/core/api";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "@Admin/core/utils";
import { CALL_HISTORY } from "@Admin/core/historyMiddleware";
import { apiSlice } from "@Shared/app/api/apiSlice";

export const apiTypes = {
    authLoginType: createApiTypes("AUTH", "LOGIN"),
    authCheckType: createApiTypes("AUTH", "CHECK", false),
    createUserType: createApiTypes("USER", "CREATE"),
    authRestoreType: createApiTypes("AUTH", "RESTORE"),
    authConfirmType: createApiTypes("AUTH", "CONFIRM"),
};

export const types = {
    USER_UPDATED: "USER_UPDATED",
    HUB_CONNECTED: "HUB_CONNECTED",
    HUB_DISCONNECTED: "HUB_DISCONNECTED",
    AUTH_ERROR: "AUTH_ERROR",
    PROMPT_SHOW: "PROMPT_SHOW",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    HIDE_LOADING: "HIDE_LOADING",
    UNHIDE_LOADING: "UNHIDE_LOADING",
};

//Reducers
const apiHandlers = createCombinedApiHandlers(apiTypes);

export const sharedReducer = createReducer(
    {},
    {
        ...apiHandlers,
        [`AUTH_ERROR`]: (state, action) => ({ ...state, authError: action.payload }),
        ["AUTHORIZED"]: (state) => ({ ...state, authorized: true, authError: null, authMessage: null }),
        ["UNAUTHORIZED"]: () => removeAuthorization() || { authorized: false },
        [`PROMPT_SHOW`]: (state, action) => ({ ...state, prompt: action.payload }),
        [`PROMPT_HIDE`]: (state, action) => ({ ...state, prompt: null }),
        [types.SHOW_LOADER]: (state) => ({ ...state, isLoading: true }),
        [types.HIDE_LOADER]: (state) => ({ ...state, isLoading: false }),
        [types.HIDE_LOADING]: (state) => ({ ...state, isLoadingHided: true }),
        [types.UNHIDE_LOADING]: (state) => ({ ...state, isLoadingHided: false }),
    },
);

//HUB
const connectToHub = () => async (dispatch, getState) => {
    try {
        await connnectToServer(KEYS.apiHost);
        console.log(`Connected: ${chatHubProxy.connectionHub.id}`);
    } catch (e) {
        return;
    }
    dispatch({ type: types.HUB_CONNECTED, payload: true });
    chatHubProxy.getMessagesCount().done((res) => {
        dispatch({ type: types.UNREAD_CHAT_MESSAGES, payload: res });
    });
};

const disconnectHub = () => (dispatch, getState) => {
    try {
        disconnectServer();
    } catch (e) {
        return;
    }
};

const checkIdentity = () => (dispatch, getState) => {
    dispatch({
        types: apiTypes.authCheckType,
        callAPI: () => proxy.authCheck(),
        payload: (state, { result }) => {
            if (result) {
                dispatch({ type: "AUTHORIZED" });
                //dispatch(connectToHub());
                if (document.location.pathname.startsWith(`/login`)) {
                    dispatch(location.push("/"));
                }
            } else {
                dispatch({ type: "UNAUTHORIZED" });
            }
        },
    });
};

const loginValidation = (login) => login.length >= 6 && login.length <= 50;
const passwordValidation = (password) => password.length >= 5 && password.length <= 50;

const loginAction = (data) => (dispatch) => {
    const { email, password } = data.passwordParameters;
    if (!loginValidation(email) || !passwordValidation(password)) {
        dispatch({
            type: types.AUTH_ERROR,
            payload: `Неправильный логин или пароль`,
        });
    } else {
        dispatch({
            types: apiTypes.authLoginType,
            callAPI: () => proxy.authLogin(data),
            payload: (state, { result, statusCode }) => {
                if (result && statusCode === 200) {
                    dispatch(apiSlice.util.resetApiState());
                    dispatch({ type: "AUTHORIZED" });
                    //dispatch(connectToHub());
                    dispatch(location.push("/"));
                } else {
                    return {
                        authError: "Неправильный логин или пароль",
                    };
                }
            },
        });
    }
};

const restoreAction = (data) => (dispatch) => {
    const { email } = data;
    if (!loginValidation(email)) {
        dispatch({
            type: types.AUTH_ERROR,
            payload: `Пользователь не найден`,
        });
    } else {
        dispatch({
            types: apiTypes.authRestoreType,
            callAPI: () => proxy.authRestore(data),
            payload: (state, { error, result }) => {
                if (result) {
                    dispatch(location.push("/login"));
                    return {
                        authMessage: `Инструкция отправлена на Ваш почтовый адрес`,
                    };
                } else {
                    return {
                        authError: `Пользователь не найден`,
                    };
                }
            },
        });
    }
};

const resetAuthError = () => (dispatch) => {
    dispatch({
        type: types.AUTH_ERROR,
        payload: null,
    });
};

const confirmAction = (loginData, passwordData) => (dispatch) => {
    if (passwordData.password !== passwordData.repeatPassword) {
        dispatch({
            type: types.AUTH_ERROR,
            payload: `Пароли не совпадают`,
        });
    } else if (!passwordValidation(passwordData.password)) {
        dispatch({
            type: types.AUTH_ERROR,
            payload: `Длина пароля должна быть от 5 до 50 символов`,
        });
    } else {
        const data = {
            ...loginData,
            password: passwordData.password,
        };
        dispatch({
            types: apiTypes.authConfirmType,
            callAPI: () => proxy.authConfirm(data),
            payload: (state, { error, result }) => {
                if (result) {
                    //dispatch(connectToHub());
                    dispatch(location.push("/login"));
                } else {
                    return {
                        authError: error,
                    };
                }
            },
        });
    }
};

const logoutAction = () => (dispatch) => {
    dispatch({
        types: apiTypes.authLoginType,
        callAPI: () => proxy.authLogout(),
        payload: (state, { result }) => {
            if (result) {
                //dispatch(disconnectHub());
                dispatch({ type: "UNAUTHORIZED" });
                dispatch(apiSlice.util.resetApiState());
            }
        },
    });
};

const registerUser = (data) => {
    return {
        types: apiTypes.createUserType,
        callAPI: () => proxy.registerUser(data),
        payload: () => {},
    };
};

function updateLocation(method) {
    return (...args) => ({
        type: CALL_HISTORY,
        payload: { method, args },
    });
}

export const location = {
    push: updateLocation("push"),
    replace: updateLocation("replace"),
    go: updateLocation("go"),
    goBack: updateLocation("goBack"),
    goForward: updateLocation("goForward"),
};

const setPromptMessage =
    (message, type = `info`) =>
    (dispatch) => {
        dispatch({
            type: `PROMPT_SHOW`,
            payload: {
                message,
                type,
            },
        });
    };

const resetPromptMessage = () => (dispatch) => {
    dispatch({ type: `PROMPT_HIDE` });
};

const hideLoadingProcess = () => (dispatch) => {
    dispatch({ type: types.HIDE_LOADING });
};
const unhideLoadingProcess = () => (dispatch) => {
    dispatch({ type: types.UNHIDE_LOADING });
};

export const sharedActions = {
    setPromptMessage,
    resetPromptMessage,
    hideLoadingProcess,
    unhideLoadingProcess,
};

export const signActions = {
    checkIdentity,
    loginAction,
    logoutAction,
    registerUser,
    restoreAction,
    confirmAction,
    resetAuthError,
};
