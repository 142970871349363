import { proxy } from "~/Admin/core/api";
import _ from "lodash";
import { createCombinedApiHandlers, createReducer, createApiTypes } from "~/Admin/core/utils";
import { tournamentDivisionSelectors } from "../../helpers/selectors";
import { formatArray } from "../../../Shared/helpers/formatters";
import { tournamentGroupActions } from "./TournamentGroupActions";
import { tournamentCategoryActions } from "./TournamentCategoryActions";
import { convertToApiModel } from "../../../Shared/helpers/tournamentDivision";

export const apiTypes = {
    loadTournamentDivisionListType: createApiTypes("TOURNAMENT_DIVISION", "LOAD", false),
    saveTemplateTournamentDivisionType: createApiTypes("TOURNAMENT_DIVISION", "SAVE_TEMPLATE", false),
    deleteTournamentDivisionType: createApiTypes("TOURNAMENT_DIVISION", "DELETE", false),
    updateTournamentDivisionType: createApiTypes("TOURNAMENT_DIVISION", "UPDATE", false),
};

export const types = {
    RESET_TOURNAMENT_DIVISION_CACHE: "RESET_TOURNAMENT_DIVISION_CACHE",
};

const apiHandlers = createCombinedApiHandlers(apiTypes);

export const tournamentDivisionReducer = createReducer(
    { cache: [] },
    {
        ...apiHandlers,
        [types.RESET_TOURNAMENT_DIVISION_CACHE]: (state) => ({ ...state, cache: [] }),
    },
);

const getTournamentDivisionList = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.loadTournamentDivisionListType,
        callAPI: () => proxy.tournamentDivisionGet(filter),
        payload: (state, { result }) => ({
            cache: [
                ...formatArray(tournamentDivisionSelectors.getTournamentDivisionCache(state)).filter(
                    (pair) => !_.isEqual(pair.filter, filter),
                ),
                {
                    filter,
                    items: result,
                },
            ],
        }),
    });
};

const deleteTournamentDivision = (filter) => (dispatch) => {
    return dispatch({
        types: apiTypes.deleteTournamentDivisionType,
        callAPI: () => proxy.tournamentDivisionDelete(filter),
        payload: (state, { result }) => {
            dispatch(resetTournamentDivisionCache());
            dispatch(tournamentGroupActions.resetTournamentGroupCache());
            dispatch(tournamentCategoryActions.resetTournamentCategoryCache());
        },
    });
};

const updateTournamentDivision = (tournamentDivisionData) => (dispatch) => {
    return dispatch({
        types: apiTypes.updateTournamentDivisionType,
        callAPI: () => proxy.tournamentDivisionUpdate(tournamentDivisionData),
        payload: (state, { result }) => {
            dispatch(resetTournamentDivisionCache());
            dispatch(tournamentGroupActions.resetTournamentGroupCache());
            dispatch(tournamentCategoryActions.resetTournamentCategoryCache());
        },
    });
};

const resetTournamentDivisionCache = () => (dispatch) => {
    return dispatch({
        type: types.RESET_TOURNAMENT_DIVISION_CACHE,
    });
};

const saveTemplateTournamentDivision =
    (tournamentDivision, onSuccess = _.noop) =>
    (dispatch) => {
        return dispatch({
            types: apiTypes.saveTemplateTournamentDivisionType,
            callAPI: () => proxy.tournamentDivisionSaveTemplate(convertToApiModel(tournamentDivision)),
            payload: (state, { result }) => {
                dispatch(resetTournamentDivisionCache());
                dispatch(tournamentGroupActions.resetTournamentGroupCache());
                dispatch(tournamentCategoryActions.resetTournamentCategoryCache());

                onSuccess(result?.id);
            },
        });
    };

export const tournamentDivisionActions = {
    getTournamentDivisionList,
    resetTournamentDivisionCache,
    saveTemplateTournamentDivision,
    deleteTournamentDivision,
    updateTournamentDivision,
};
