import _ from "lodash";

export const ranks = [
    { id: 0, key: "empty", name: "Нет пояса" },
    { id: 1, key: "geup10", name: "10 ГЫП" },
    { id: 2, key: "geup9", name: "9 ГЫП" },
    { id: 3, key: "geup8", name: "8 ГЫП" },
    { id: 4, key: "geup7", name: "7 ГЫП" },
    { id: 5, key: "geup6", name: "6 ГЫП" },
    { id: 6, key: "geup5", name: "5 ГЫП" },
    { id: 7, key: "geup4", name: "4 ГЫП" },
    { id: 8, key: "geup3", name: "3 ГЫП" },
    { id: 9, key: "geup2", name: "2 ГЫП" },
    { id: 10, key: "geup1", name: "1 ГЫП" },
    { id: 11, key: "poom1", name: "1 ПУМ" },
    { id: 12, key: "poom2", name: "2 ПУМ" },
    { id: 13, key: "poom3", name: "3 ПУМ" },
    { id: 14, key: "poom4", name: "4 ПУМ" },
    { id: 15, key: "dan1", name: "1 ДАН" },
    { id: 16, key: "dan2", name: "2 ДАН" },
    { id: 17, key: "dan3", name: "3 ДАН" },
    { id: 18, key: "dan4", name: "4 ДАН" },
    { id: 19, key: "dan5", name: "5 ДАН" },
    { id: 20, key: "dan6", name: "6 ДАН" },
    { id: 21, key: "dan7", name: "7 ДАН" },
    { id: 22, key: "dan8", name: "8 ДАН" },
    { id: 23, key: "dan9", name: "9 ДАН" },
    { id: 24, key: "dan10", name: "10 ДАН" },
] as const;

export type Ranks = typeof ranks;
export type Rank = Ranks[number];
export type RankId = Rank["id"];
export type RankKey = Rank["key"];
export type RankById<TId extends RankId> = Extract<Rank, { id: TId }>;
export type RankByKey<TKey extends RankKey> = Extract<Rank, { key: TKey }>;
export type RankIds = { [key in RankKey]: RankByKey<key>["id"] };

export const rankIds = _.reduce(ranks, (r, g) => ({ ...r, [g.key]: g.id }), {}) as RankIds;

export const isRankId = (value: any): value is RankId => ranks.some((t) => t.id === value);

export const isRankKey = (value: any): value is RankKey => ranks.some((t) => t.key === value);

export const getRankByKey = <TKey extends RankKey>(key: TKey) => ranks.find((t) => t.key === key) as RankByKey<TKey>;

export const getRankById = <TId extends RankId>(id: TId) => ranks.find((r) => r.id === id) as RankById<TId>;

export const getCurrentRankId = (current: number | undefined, last: number | undefined, isCurrent = false) => {
    if (current && !last) {
        return current;
    }

    if (last) {
        if (current) {
            return isCurrent ? current : last;
        }

        return last;
    }

    return undefined;
};
