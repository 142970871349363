import _ from "lodash";

export const genders = [
    { id: 1, key: "male", name: "Мужчина", nameMany: "Мужчины" },
    { id: 2, key: "female", name: "Женщина", nameMany: "Женщины" },
] as const;

export type Genders = typeof genders;
export type Gender = Genders[number];
export type GenderId = Gender["id"];
export type GenderKey = Gender["key"];
export type GenderById<TId extends GenderId> = Extract<Gender, { id: TId }>;
export type GenderByKey<TKey extends GenderKey> = Extract<Gender, { key: TKey }>;
export type GenderIds = { [key in GenderKey]: GenderByKey<key>["id"] };

export const genderIds = _.reduce(genders, (r, g) => ({ ...r, [g.key]: g.id }), {}) as GenderIds;

export const isGenderId = (value: any): value is GenderId => genders.some((t) => t.id === value);

export const isGenderKey = (value: any): value is GenderKey => genders.some((t) => t.key === value);

export const getGenderByKey = <TKey extends GenderKey>(key: TKey) =>
    genders.find((t) => t.key === key) as GenderByKey<TKey>;

export const getGenderById = <TId extends GenderId>(id: TId) => genders.find((r) => r.id === id) as GenderById<TId>;
