import { sharedReducer } from "~/Admin/redux/actions/SharedActions";
import { profileReducer } from "~/Admin/redux/actions/ProfileActions";
import { clubReducer } from "~/Admin/redux/actions/ClubActions";
import { settingsReducer } from "~/Admin/redux/actions/SettingsActions";
import { memberReducer } from "~/Admin/redux/actions/MemberActions";
import { tournamentReducer } from "~/Admin/redux/actions/TournamentActions";
import { tournamentAttachmentReducer } from "~/Admin/redux/actions/TournamentAttachmentActions";
import {
    tournamentStatusReducer,
    tournamentStatusWithFilterReducer,
} from "~/Admin/redux/actions/TournamentStatusActions";
import { tournamentMemberReducer } from "~/Admin/redux/actions/TournamentMemberActions";
import { tournamentCategoryReducer } from "~/Admin/redux/actions/TournamentCategoryActions";
import { tournamentDivisionReducer } from "~/Admin/redux/actions/TournamentDivisionActions";
import { tournamentGroupReducer } from "~/Admin/redux/actions/TournamentGroupActions";
import { tournamentNodeReducer } from "~/Admin/redux/actions/TournamentNodeActions";
import { tournamentNodeMemberReducer } from "~/Admin/redux/actions/TournamentNodeMemberActions";
import { tournamentCarpetReducer } from "~/Admin/redux/actions/TournamentCarpetActions";
import { reportReducer } from "~/Admin/redux/actions/ReportActions";
import { publicReducer } from "~/Admin/redux/actions/PublicActions";
import filterReducer from "@Shared/features/filters/filtersSlice";

export default {
    tournament: tournamentReducer,
    tournamentAttachment: tournamentAttachmentReducer,
    tournamentStatus: tournamentStatusReducer,
    tournamentStatusWithFilter: tournamentStatusWithFilterReducer,
    tournamentMember: tournamentMemberReducer,
    tournamentCategory: tournamentCategoryReducer,
    tournamentDivisions: tournamentDivisionReducer,
    tournamentGroups: tournamentGroupReducer,
    tournamentNodes: tournamentNodeReducer,
    tournamentNodeMembers: tournamentNodeMemberReducer,
    tournamentCarpets: tournamentCarpetReducer,
    profile: profileReducer,
    shared: sharedReducer,
    club: clubReducer,
    settings: settingsReducer,
    member: memberReducer,
    report: reportReducer,
    public: publicReducer,
    filter: filterReducer,
};
